export const Ebook1 = require("./CIVIL/COBRO DE CUOTAS DE CONDOMINIO, CLUBS, ENTIDADES EDUCATIVAS Y OTRAS - MONITORIO.docx");
export const Ebook2 = require("./CIVIL/COBRO DE DEUDA SIN TÍTULO EJECUTIVO - MONITORIO.docx");
export const Ebook3 = require("./CIVIL/COBRO DE PENSIONES DE ARRENDAMIENTO - INQUILINATO - MONITORIO.docx");
export const Ebook4 = require("./CIVIL/COBRO LETRA DE CAMBIO - EJECUTIVO.docx");
export const Ebook5 = require("./CIVIL/COBRO LETRA DE CAMBIO CON PROVIDENCIAS PREVENTIVAS - EJECUTIVO.docx");
export const Ebook6 = require("./CIVIL/COBRO PAGARE A LA ORDEN - EJECUTIVO.docx");
export const Ebook7 = require("./CIVIL/EJECUCIÓN DE PRENDA INDUSTRIAL - PROCEDIMIENTO DE EJECUCIÓN.docx");
export const Ebook8 = require("./CIVIL/EJECUCIÓN DE SENTENCIA EJECUTORIADA  CON OBLIGACIÓN DE  DAR DINERO O BIENES DE GÉNERO.docx");
export const Ebook9 = require("./CIVIL/EJECUCIÓN DE SENTENCIA EJECUTORIADA CON OBLIGACIÓN DE HACER.docx");
export const Ebook10 = require("./CIVIL/EJECUCIÓN DE SENTENCIA EJECUTORIADA CON OBLIGACIÓN DE NO HACER.docx");
export const Ebook11 = require("./CIVIL/EJECUCIÓN DE SENTENCIA EJECUTORIADA CON OBLIGACIÓN DE DAR ESPECIE O CUERPO CIERTO.docx");
export const Ebook12 = require("./CIVIL/INSOLVENCIA – CONCURSO NECESARIO DE ACREEDORES - CONCURSAL.docx");
export const Ebook13 = require("./CIVIL/OBRA NUEVA - SUMARIO.docx");
export const Ebook14 = require("./CIVIL/PAGO POR CONSIGNACIÓN - VOLUNTARIO.docx");
export const Ebook15 = require("./CIVIL/PRESCRIPCIÓN EXTRAORDINARIA ADQUISITIVA DE DOMINIO -  ORDINARIO.docx");
export const Ebook16 = require("./CIVIL/PRESCRIPCIÓN ORDINARIA DE DOMINIO - ORDINARIO.docx");
export const Ebook17 = require("./CIVIL/PRESUNCIÓN DE MUERTE - ORDINARIO.docx");
export const Ebook18 = require("./CIVIL/REFORMA DE TESTAMENTO - ORDINARIO.docx");
export const Ebook19 = require("./CIVIL/REIVINDICACIÓN - ORDINARIO.docx");
export const Ebook20 = require("./CIVIL/RESCISIÓN DE CONTRATO POR LESIÓN ENORME POR PAGO MAYOR AL JUSTO PRECIO - ORDINARIO.docx");
export const Ebook21 = require("./CIVIL/RESCISIÓN DE CONTRATO POR LESIÓN ENORME POR PAGO MENOR AL JUSTO PRECIO - ORDINARIO.docx");
export const Ebook22 = require("./CIVIL/RESCISIÓN DE CONTRATO POR VICIOS REDHIBITORIOS - ORDINARIO.docx");
export const Ebook23 = require("./CIVIL/RESOLUCIÓN DE CONTRATO DE PROMESA DE COMPRAVENTA -  ORDINARIO.docx");
export const Ebook24 = require("./CIVIL/TERMINACIÓN DE LA UNIÓN DE HECHO CONTROVERTIDA CON HIJOS DEPENDIENTES -  VOLUNTARIO.docx");
export const Ebook25 = require("./CIVIL/TERMINACIÓN DE LA UNIÓN DE HECHO CONTROVERTIDA Y LIQUIDACIÓN DE LA SOCIEDAD DE BIENES CON HIJOS DEPENDIENTES - VOLUNTARIO.docx");
export const Ebook26 = require("./CONSTITUCIONAL/ACCIÓN ORDINARIA DE PROTECCIÓN.docx");
export const Ebook27 = require("./CONSTITUCIONAL/HABEAS CORPUS.docx");
export const Ebook28 = require("./CONSTITUCIONAL/HABEASDATA.docx");
export const Ebook29 = require("./CONTRATOS/CONTRATO DE ARRENDAMIENTO MEDIANTE ESCRITURA PÚBLICA.docx");
export const Ebook30 = require("./CONTRATOS/CONTRATO DE ARRENDAMIENTO.docx");
export const Ebook31 = require("./CONTRATOS/CONTRATO DE COMPRAVENTA DE VEHICULO.docx");
export const Ebook32 = require("./CONTRATOS/CONTRATO DE TRABAJO  CON JORNADA PARCIAL PERMANENTE CON PERÍODO DE PRUEBA.docx");
export const Ebook33 = require("./CONTRATOS/CONTRATO DE TRABAJO CON JORNADA PARCIAL PERMANENTE PARA SERVICIO DOMESTICO CON PERÍODO DE PRUEBA.docx");
export const Ebook34 = require("./CONTRATOS/CONTRATO DE TRABAJO DE SERVICIO DOMÉSICO POR TIEMPO INDEFINIDO CON PERÍODO DE PRUEBA.docx");
export const Ebook35 = require("./CONTRATOS/CONTRATO DE TRABAJO POR TIEMPO INDEFINIDO CON PERÍODO DE PRUEBA.docx");
export const Ebook36 = require("./FAMILIA/ALIMENTOS PARA MUJER EMBARAZADA - SUMARIO.docx");
export const Ebook37 = require("./FAMILIA/AUTORIZACIÓN DE SALIDA DE MENOR - NOTARIAL.docx");
export const Ebook38 = require("./FAMILIA/AUTORIZACIÓN VENTA IMNUEBLE DE MENOR - VOLUNTARIO.docx");
export const Ebook39 = require("./FAMILIA/DILIGENCIA PREPARATORIA, PARA EN UN FUTURO PROCESO PEDIR LA PÉRDIDA JUDICIAL DE PATRIA POTESTAD.docx");
export const Ebook40 = require("./FAMILIA/DISOLUCIÓN UNILATERAL  DE LA SOCIEDAD CONYUGAL - VOLUNTARIO.docx");
export const Ebook41 = require("./FAMILIA/DIVORCIO CONTENCIOSO POR ABANDONO - SUMARIO.docx");
export const Ebook42 = require("./FAMILIA/DIVORCIO CONTENCIOSO POR ABANDONO CON LIQUIDACIÓN DE LA SOCIEDAD CONYUGAL - SUMARIO.docx");
export const Ebook43 = require("./FAMILIA/DIVORCIO POR MUTUO CONSENTIMIENTO CON HIJOS DEPENDIENTES -VOLUNTARIO.docx");
export const Ebook44 = require("./FAMILIA/DIVORCIO POR MUTUO CONSENTIMIENTO Y LIQUIDACIÓN DE LA SOCIEDAD CONYUGAL  CON HIJOS DEPENDIENTES -  VOLUNTARIO.docx");
export const Ebook45 = require("./FAMILIA/EJECUCIÓN DE ACTA DE MEDIACIÓN POR PENSIONES ALIMENTICIAS CON OBLIGACIÓN DE DAR DINERO O BIENES DE GÉNERO - FAMILIA - PROCEDIMIENTO DE EJECUCIÓN.docx");

export const Ebook46 = require("./FAMILIA/IMPUGNACIÓN DE PATERNIDAD – SUMARIO.docx");
export const Ebook47 = require("./FAMILIA/INSCRIPCIÓN TARDÍA DE NACIMINETO.docx");
export const Ebook48 = require("./FAMILIA/NOMBRAMIENTO DE CURADOR PARA SEGUNDAS NUPCIAS – VOLUNTARIO.docx");
export const Ebook49 = require("./FAMILIA/NULIDAD DE MATRIMONIO CIVIL – ORDINARIO.docx");
export const Ebook50 = require("./FAMILIA/PÉRDIDA JUDICIAL DE PATRIA POTESTAD.docx");
export const Ebook51 = require("./FAMILIA/SOLICITUD DE PROTOCOLIZACIÓN DE ACTA DE DISCERNINIENTO Y POSESIÓN DE CURADOR ESPECIAL PARA SEGUNDAS NUPCIAS - NOTARIAL.docx");
export const Ebook52 = require("./FAMILIA/TERMINACIÓN DE LA UNIÓN DE HECHO POR MUTUO CONSENTIMIENTO CON HIJOS DEPENDIENTES - VOLUNTARIO.docx");
export const Ebook53 = require("./FAMILIA/TERMINACIÓN DE LA UNIÓN DE HECHO POR MUTUO CONSENTIMIENTO CON HIJOS DEPENDIENTES CON LIQUIDACIÓN DE BIENES - VOLUNTARIO.docx");
export const Ebook54 = require("./INQUILINATO/CONTRATO DE ARRENDAMIENTO MEDIANTE ESCRITURA PÚBLICA.docx");
export const Ebook55 = require("./INQUILINATO/CONTRATO DE ARRENDAMIENTO.docx");
export const Ebook56 = require("./INQUILINATO/NOTIFICACIÓN DE SOLEMNIZACIÓN DE  DESAHUCIO DE ARRENDAMIENTO.docx");
export const Ebook57 = require("./INQUILINATO/TERMINACIÓN DE CONTRATO DE ARRENDAMIENTO POR CAUSAL.docx");
export const Ebook58 = require("./INQUILINATO/TERMINACIÓN DE CONTRATO DE ARRENDAMIENTO POR MORA.docx");
export const Ebook59 = require("./LABORAL/ACUERDO DE JUBILACIÓN PATRONAL MEDIANTE FONDO GLOBAL  - NOTARIAL.docx");
export const Ebook60 = require("./LABORAL/COBRO DE REMUNERACIONES - MONITORIO.docx");
export const Ebook61 = require("./LABORAL/CONTRATO DE TRABAJO  CON JORNADA PARCIAL PERMANENTE CON PERÍODO DE PRUEBA.docx");
export const Ebook62 = require("./LABORAL/CONTRATO DE TRABAJO CON JORNADA PARCIAL PERMANENTE PARA SERVICIO DOMESTICO CON PERÍODO DE PRUEBA.docx");
export const Ebook63 = require("./LABORAL/CONTRATO DE TRABAJO DE SERVICIO DOMÉSICO POR TIEMPO INDEFINIDO CON PERÍODO DE PRUEBA.docx");
export const Ebook64 = require("./LABORAL/CONTRATO DE TRABAJO POR TIEMPO INDEFINIDO CON PERÍODO DE PRUEBA.docx");
export const Ebook65 = require("./LABORAL/DENUNCIA INSPECTORIA DEL TRABAJO PARA PAGO DE LIQUIDACIÓN Y FINIQUITO.docx");
export const Ebook66 = require("./LABORAL/DENUNCIA INSPECTORIA DEL TRABAJO POR ACCIDENTE DE TRABAJO O ENFERMEDAD PROFESIONAL.docx");
export const Ebook67 = require("./LABORAL/DESPIDO INTEMPESTIVO.docx");
export const Ebook68 = require("./LABORAL/NOTIFICACIÓN DE DESAHUCIO LABORAL.docx");
export const Ebook69 = require("./LABORAL/SOLICITUD DE VISTO BUENO ANTE INSPECTORÍA DEL TRABAJO CON SUSPENSIÓN DE LABORES.docx");
export const Ebook70 = require("./LABORAL/SOLICITUD DE VISTO BUENO SIN SUSPENCIÓN DE LABORES.docx");
export const Ebook71 = require("./MULTICOMPETENTE/DILIGENCIA PREPARATORIA DE EXHIBICIÓN.docx");
export const Ebook72 = require("./MULTICOMPETENTE/DILIGENCIA PREPARATORIA DE RECONOCIMIENTO DE DOCUMENTO PRIVADO .docx");
export const Ebook73 = require("./NOTARIAL/ACLARATORIA - MINUTA.docx");
export const Ebook74 = require("./NOTARIAL/ACLARATORIA Y RECTIFICATORIA - MINUTA.docx");
export const Ebook75 = require("./NOTARIAL/ACUERDO DE JUBILACIÓN PATRONAL MEDIANTE FONDO GLOBAL  - LABORAL.docx");
export const Ebook76 = require("./NOTARIAL/AUMENTO DE CAPITAL  Y REFORMA DE ESTATUTOS  DE UNA COMPAÑÍA-  SOCIETARIO.docx");
export const Ebook77 = require("./NOTARIAL/AUTORIZACIÓN DE ADOSAMIENTO - MINUTA.docx");
export const Ebook78 = require("./NOTARIAL/AUTORIZACIÓN DE SALIDA DE MENOR - FAMILIA.docx");
export const Ebook79 = require("./NOTARIAL/AUTORIZACIÓN MUTUA - MINUTA.docx");
export const Ebook80 = require("./NOTARIAL/CAMBIO DE DOMICILIO  Y REFORMA DE ESTATUTOS  DE UNA COMPAÑÍA- SOCIETARIO.docx");
export const Ebook81 = require("./NOTARIAL/CAMBIO DE OBJETO SOCIAL  Y REFORMA DE ESTATUTOS  DE UNA COMPAÑÍA- SOCIETARIO.docx");
export const Ebook82 = require("./NOTARIAL/CAMBIO DE RAZÓN SOCIAL  Y REFORMA DE ESTATUTOS  DE UNA COMPAÑÍA- SOCIETARIO.docx");
export const Ebook83 = require("./NOTARIAL/CANCELACIÓN DE HIPOTECA  ABIERTA.docx");
export const Ebook84 = require("./NOTARIAL/CAPITULACIONES MATRIMONIALES ENTRE CÓNYUGES.docx");
export const Ebook85 = require("./NOTARIAL/CAPITULACIONES MATRIMONIALES PARA FUTURAS NUPCIAS.docx");
export const Ebook86 = require("./NOTARIAL/CERTIFICACIÓN DE DOCUMENTO DESCARGADO DESDE LA WEB.docx");
export const Ebook87 = require("./NOTARIAL/CESIÓN DE DERECHOS  Y MEJORAS.docx");
export const Ebook88 = require("./NOTARIAL/CESIÓN DE PARTICIPACIONES EN COMPAÑÍA LIMITADA - SOCIETARIO.docx");
export const Ebook89 = require("./NOTARIAL/COMPRAVENTA DE INMUEBLE BAJO RÉGIMEN DE PROPIEDAD HORIZONTAL.docx");
export const Ebook90 = require("./NOTARIAL/COMPRAVENTA DE INMUEBLE.docx");
export const Ebook91 = require("./NOTARIAL/CONTRATO DE ARRENDAMIENTO MEDIANTE ESCRITURA PÚBLICA - INQUILINATO.docx");
export const Ebook92 = require("./NOTARIAL/DACIÓN EN PAGO.docx");
export const Ebook93 = require("./NOTARIAL/DECLARACIÓN JURAMENTADA.docx");
export const Ebook94 = require("./NOTARIAL/DONACIÓN CON  RESERVA DE USUFRUCTO.docx");
export const Ebook95 = require("./NOTARIAL/DONACIÓN SIN RESERVA DE USUFRUCTO.docx");
export const Ebook96 = require("./NOTARIAL/EXTINCIÓN DE USUFRUCTO USO Y HABITACIÓN.docx");
export const Ebook97 = require("./NOTARIAL/INFORMACION SUMARIA.docx");
export const Ebook98 = require("./NOTARIAL/INSINUACIÓN JUDICIAL PARA DONACIÓN CON RESERVA DE USUFRUCTO.docx");
export const Ebook99 = require("./NOTARIAL/INSINUACIÓN JUDICIAL PARA DONACIÓN SIN RESERVA DE USUFRUCTO.docx");
export const Ebook100 = require("./NOTARIAL/NOMBRAMIENTO DE PROCURADOR COMÚN.docx");
export const Ebook101 = require("./NOTARIAL/NOTIFICACIÓN DE SOLEMNIZACIÓN DE  DESAHUCIO DE ARRENDAMIENTO.docx");
export const Ebook102 = require("./NOTARIAL/PODER ESPECIAL.docx");
export const Ebook103 = require("./NOTARIAL/POSESIÓN EFECTIVA CON CÓNYUGE SOBREVIVIENTE Y COMPARECENCIA DE TODOS LOS HEREDEROS CON ENUMERACIÓN DE BIENES.docx");
export const Ebook104 = require("./NOTARIAL/POSESIÓN EFECTIVA CON CÓNYUGE SOBREVIVIENTE Y COMPARECENCIA DE TODOS LOS HEREDEROS SIN ENUMERACIÓN DE BIENES.docx");
export const Ebook105 = require("./NOTARIAL/PROCURACIÓN JUDICIAL.docx");
export const Ebook106 = require("./NOTARIAL/PROMESA DE COMPRAVENTA.docx");
export const Ebook107 = require("./NOTARIAL/PRORROGA DE VIGENCIA DEL CONTRATO SOCIAL Y REFORMA DE ESTATUTOS DE UNA COMPAÑÍA.docx");
export const Ebook108 = require("./NOTARIAL/REACTIVACIÓN Y REFORMA DE ESTATUTOS DE UNA COMPAÑÍA.docx");
export const Ebook109 = require("./NOTARIAL/RESCILIACIÓN.docx");
export const Ebook110 = require("./NOTARIAL/REVOCATORIA EN CONSENSO DE PODER.docx");
export const Ebook111 = require("./NOTARIAL/REVOCATORIA UNILATERAL DE PODER.docx");
export const Ebook112 = require("./NOTARIAL/SOLEMNIZAR UNIÓN DE HECHO.docx");
export const Ebook113 = require("./NOTARIAL/SOLICITUD DE DILIGENCIA DE AMOJONAMIENTO Y DESLINDE RURAL.docx");
export const Ebook114 = require("./NOTARIAL/SOLICITUD DE PROTOCOLIZACIÓN DE ACTA DE DISCERNINIENTO Y POSESIÓN DE CURADOR ESPECIAL PARA SEGUNDAS NUPCIAS.docx");
export const Ebook115 = require("./NOTARIAL/SUBROGACIÓN PATRIMONIO FAMILIAR.docx");
export const Ebook116 = require("./PENAL/ACUSACION PARTICULAR ACCIDENTE DE TRÁNSITO.docx");
export const Ebook117 = require("./PENAL/ACUSACION PARTICULAR EN DELITO DE ACCIÓN PÚBLICA.docx");
export const Ebook118 = require("./PENAL/DENUNCIA DEFENSA DEL CONSUMIDOR - CONTRAVENCIÓN.docx");
export const Ebook119 = require("./PENAL/DENUNCIA ROBO.docx");
export const Ebook120 = require("./PENAL/IMPUGNACIÓN NOTIFICACIÓN TRÁNSITO.docx");
export const Ebook121 = require("./PENAL/QUERELLA POR CALUMNIA ATREVES DE REDES SOCIALES, FACEBOOK, TWITTER, WHATSAPP, CORREO ELECTRÓNICO, ETC..docx");
export const Ebook122 = require("./PENAL/QUERELLA POR LESIONES.docx");
export const Ebook123 = require("./SOCIETARIO/AUMENTO DE CAPITAL  Y REFORMA DE ESTATUTOS  DE UNA COMPAÑÍA-  NOTARIAL.docx");
export const Ebook124 = require("./SOCIETARIO/CAMBIO DE DOMICILIO  Y REFORMA DE ESTATUTOS  DE UNA COMPAÑÍA-  NOTARIAL.docx");
export const Ebook125 = require("./SOCIETARIO/CAMBIO DE OBJETO SOCIAL  Y REFORMA DE ESTATUTOS  DE UNA COMPAÑÍA-  NOTARIAL.docx");
export const Ebook126 = require("./SOCIETARIO/CAMBIO DE RAZÓN SOCIAL  Y REFORMA DE ESTATUTOS  DE UNA COMPAÑÍA-  NOTARIAL.docx");
export const Ebook127 = require("./SOCIETARIO/CESIÓN DE PARTICIPACIONES EN COMPAÑÍA LIMITADA - NOTARIAL.docx");
export const Ebook128 = require("./SOCIETARIO/PRORROGA DE VIGENCIA DEL CONTRATO SOCIAL Y REFORMA DE ESTATUTOS DE UNA COMPAÑÍA – NOTARIAL.docx");
export const Ebook129 = require("./SOCIETARIO/REACTIVACIÓN Y REFORMA DE ESTATUTOS DE UNA COMPAÑÍA – NOTARIAL.docx");
