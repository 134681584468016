//Import React Library
import React, { Component } from "react";

//Import Material Design Bootstrap components for React
import { MDBRow, p, MDBCol, MDBBox } from "mdbreact";

import Rectangle from "./Rectangle";
export default class SectionTitle extends Component {
  render() {
    return (
      <MDBCol className="py-5 px-4">
        <MDBRow className="noSpace">
          <MDBCol size="6" md="4" lg="3" xl="2">
            <img //image
                            className="img-fluid"
                            src={this.props.src}
                            alt="title">
                        </img>
          </MDBCol>
          <MDBBox className="noSpace my-auto">
            <Rectangle width="50" height="50" color="#13Adbd" />
          </MDBBox>
          <MDBCol className="noSpace pl-4 my-auto">
            <Rectangle width="100%" height="50" color="#153542" />
          </MDBCol>
        </MDBRow>
        <p
          tag="h3"
          variant="h3-responsive"
          className="GothamRoundedBold text-center pb-3"
        >
          {this.props.title}
        </p>{" "}
        {/**Title */}
      </MDBCol>
    );
  }
}
