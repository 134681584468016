//Import React Library
import React, { Component } from "react";

// Create Context Api (used for state management)
const ProductContext = React.createContext();
//parse url parameter
const queryString = require("query-string");
//jwt decode library
var jwtDecode = require("jwt-decode");
var token = "";
var productName = "";
class ProductProvider extends Component {
  //State definition for all the app
  state = {
    url: "http://services.finder.lexis.com.ec/api/LamService/ValidateUser",
    urlRedirect: "/",
    collapseID: "0",
    producto: "",
    isRoEnabled: false,
    modal: false,
    modalUrl: "",
  };

  async componentDidMount() {
    //console.log(sessionStorage.getItem('lhl'))

    //console.log(sessionStorage.getItem('productName'))
    var urlInfo = queryString.parseUrl(window.location.href);
    if (
      (sessionStorage.getItem("lhl") === null) |
      (sessionStorage.getItem("lhl") === undefined)
    ) {
      token = urlInfo.query.token;
      //console.log(token)
    } else {
      token = sessionStorage.getItem("lhl");
      //console.log(token)
      //this.setState({ isRoEnabled: true });
    }

    if (
      (sessionStorage.getItem("productName") === null) |
      (sessionStorage.getItem("productName") === undefined)
    ) {
      productName = urlInfo.query.productName;
      sessionStorage.setItem("productName", productName);
      //console.log(productName)
    } else {
      productName = sessionStorage.getItem("productName");
      //console.log(productName)
    }

    var datos = { token: token };
    if ((productName !== null) & (productName !== undefined)) {
      console.log(productName);
      if (productName === "LOYAL") {
        this.setState({
          urlRedirect:
            "https://loyal.finder.lexis.com.ec/WebTools/LexisFinder/Default.aspx",
        });
      } else if (productName === "TOTAL") {
        this.setState({
          urlRedirect:
            "https://total.finder.lexis.com.ec/WebTools/LexisFinder/Default.aspx",
        });
      } else if (productName === "LEGACY") {
        this.setState({
          urlRedirect:
            "https://legacy.finder.lexis.com.ec/WebTools/LexisFinder/Default.aspx",
        });
      } else if (productName === "Z-ONE") {
        this.setState({
          urlRedirect:
            "https://zone.finder.lexis.com.ec/WebTools/LexisFinder/Default.aspx",
        });
      } else if (productName === "JURLEX") {
        this.setState({
          urlRedirect:
            "https://jurlex.finder.lexis.com.ec/WebTools/LexisFinder/Default.aspx",
        });
      }
    }
    if ((token !== null) & (token !== undefined) & (token !== "")) {
      // console.log("entra token dif null")
      await fetch("http://lam.finder.lexis.com.ec/api/v1/auth/validateToken", {
        // await fetch("http://192.168.85.128:49461/api/v1/auth/validateToken", {
        method: "post",
        body: JSON.stringify(datos),
        headers: { "Content-Type": "application/json" },
      })
        .then((response) => {
          if (!response.ok) {
            return response
              .json()
              .catch(() => {
                // Couldn't parse the JSON
                console.log(response.status);
                this.setState({ isRoEnabled: false });

                sessionStorage.setItem("lhl", null);
              })
              .then(({ message }) => {
                // Got valid JSON with error response, use it
                console.log(message || response.status);
                this.setState({ isRoEnabled: false });

                sessionStorage.setItem("lhl", null);
                /*this.setState(() => {
                                    return {
                                        colorAlert: "danger",
                                        textAlert: message,
                                        visibility: "visible"
                                    };
                                }); //Set alert fields according to fetch response (state=error)*/
              });
          }

          // Successful response, parse the JSON and return the data
          return response.json();
        })
        .then((json) => {
          console.log("parsed json", json);
          if (json !== undefined) {
            if (json.length === 0) {
              var decoded = jwtDecode(token);
              console.log(decoded);
              var producto = decoded.productName;
              sessionStorage.setItem("productName", producto);
              if (producto === "LOYAL") {
                this.setState({
                  urlRedirect:
                    "https://loyal.finder.lexis.com.ec/WebTools/LexisFinder/Default.aspx",
                });
              } else if (producto === "TOTAL") {
                this.setState({
                  urlRedirect:
                    "https://total.finder.lexis.com.ec/WebTools/LexisFinder/Default.aspx",
                });
              } else if (producto === "LEGACY") {
                this.setState({
                  urlRedirect:
                    "https://legacy.finder.lexis.com.ec/WebTools/LexisFinder/Default.aspx",
                });
              } else if (producto === "Z-ONE") {
                this.setState({
                  urlRedirect:
                    "https://zone.finder.lexis.com.ec/WebTools/LexisFinder/Default.aspx",
                });
              } else if (producto === "JURLEX") {
                this.setState({
                  urlRedirect:
                    "https://jurlex.finder.lexis.com.ec/WebTools/LexisFinder/Default.aspx",
                });
              } else {
                this.setState({
                  urlRedirect: "https://finder.lexis.com.ec/Login/login.aspx",
                });
              }

              this.setState({ isRoEnabled: true });

              sessionStorage.setItem("lhl", token);
              /*this.setState(() => {
                                return {
                                    colorAlert: "success",
                                    textAlert: "Datos guardados exitosamente",
                                    visibility: "visible"
                                };
                            }); //Set alert fields according to fetch response (state=ok) */
            } else {
              var strError = "";
              console.log(json[0].message);
              json.forEach((element) => {
                strError = strError + ", " + element.message;
              });
              console.log(strError);
              this.setState({ isRoEnabled: false });
              sessionStorage.setItem("productName", null);
            }
          } else {
            this.setState({ isRoEnabled: false });
            sessionStorage.setItem("productName", null);
          }
        });
    }

    /*curl --location --request POST 'http://localhost:49461/api/v1/auth/validateToken' \

--header 'Content-Type: application/json' \

--data-raw '{"token": "eyJhbGciOiJodHRwOi8vd3d3LnczLm9yZy8yMDAxLzA0L3htbGRzaWctbW9yZSNobWFjLXNoYTI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6ImNlc2FyX3NldmlsbGFfZWNAaG90bWFpbC5jb20iLCJwcm9kdWN0TmFtZSI6IlRPVEFMIiwiZW5kRGF0ZSI6IjIwMjAtMDMtMjhUMTY6MzM6MzcuNzg5MTk3N1oifQ.5-YHib_sF6wi9punvq-bfS-2a8f7o1OTHxLC5Onzll4"}' */
  }
  //Shows/hides desired collapse container
  toggleCollapse = (collapseID) => {
    this.setState((prevState) => ({
      collapseID: prevState.collapseID !== collapseID ? collapseID : "",
    }));
  };

  toggleModal = (url) => {
    /*if(e!==undefined){
            e.preventDefault();
        }*/

    console.log("modal state");
    this.setState({
      modal: !this.state.modal,
      modalUrl: url,
    });
  };

  render() {
    return (
      <ProductContext.Provider
        value={{
          ...this.state,
          toggleCollapse: this.toggleCollapse,
          toggleModal: this.toggleModal,
        }} // Enable access to states and functions from ProductProvider
      >
        {this.props.children}
      </ProductContext.Provider>
    );
  }
}

//create context objet for consumer
const ProductConsumer = ProductContext.Consumer;

export { ProductProvider, ProductConsumer };
