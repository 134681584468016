//Import react library
import React, { Component } from "react";
//Import material design components
import { MDBCollapse } from "mdbreact";
//Import scroll component
import { scroller } from "react-scroll";
// Import ProductConsumer from Context Api (used for state management)
import { ProductConsumer } from "../../Context/context";

//Import Layouts
import Vademecum from "./Vademecum";
import Modelos from "./Modelo";

//Define variable
var Wrap = ({ children }) => <Vademecum />;
export default class Collapse extends Component {
  render() {
    return (
      <ProductConsumer>
        {(
          value //value contains the data defined in the product provider in context.js
        ) => {
          //Define layout according to incoming props
          if (this.props.id === "1") {
            Wrap = ({ children }) => <Vademecum />;
          } else if (this.props.id === "2") {
            Wrap = ({ children }) => <Modelos />;
          }

          return (
            <MDBCollapse //Collapse component
              id={this.props.id}
              isOpen={value.collapseID}
              onOpened={() =>
                scroller.scrollTo(this.props.id, {
                  duration: 800,
                  delay: 0,
                  smooth: "easeInOutQuart",
                  offset: -80,
                })
              }
            >
              <Wrap />
            </MDBCollapse>
          );
        }}
      </ProductConsumer>
    );
  }
}
