import * as bookList from "./booksList";

export const books = [
  {
    id: 1,
    nombre: "ACTUACIONES DE INVESTIGACIÓN",
    fecha: 2014,
    tipo: "Vademecum procesal",
    materia: "Código Integral Penal",
    pdf: bookList["Ebook1"],
  },
  {
    id: 2,
    nombre: "ACTUACIONES ESPECIALES DE INVESTIGACIÓN",
    fecha: 2014,
    tipo: "Vademecum procesal",
    materia: "Código Integral Penal",
    pdf: bookList["Ebook2"],
  },
  {
    id: 3,
    nombre: "ACUSACIÓN PARTICULAR",
    fecha: 2014,
    tipo: "Vademecum procesal",
    materia: "Código Integral Penal",
    pdf: bookList["Ebook3"],
  },
  {
    id: 4,
    nombre: "ALLANAMIENTO - PROCEDIMIENTO",
    fecha: 2014,
    tipo: "Vademecum procesal",
    materia: "Código Integral Penal",
    pdf: bookList["Ebook4"],
  },
  {
    id: 5,
    nombre: "ALLANAMIENTO",
    fecha: 2014,
    tipo: "Vademecum procesal",
    materia: "Código Integral Penal",
    pdf: bookList["Ebook5"],
  },
  {
    id: 6,
    nombre: "CAUCIÓN",
    fecha: 2014,
    tipo: "Vademecum procesal",
    materia: "Código Integral Penal",
    pdf: bookList["Ebook6"],
  },
  {
    id: 7,
    nombre: "COMPETENCIA",
    fecha: 2014,
    tipo: "Vademecum procesal",
    materia: "Código Integral Penal",
    pdf: bookList["Ebook7"],
  },
  {
    id: 8,
    nombre: "DENUNCIA",
    fecha: 2014,
    tipo: "Vademecum procesal",
    materia: "Código Integral Penal",
    pdf: bookList["Ebook8"],
  },
  {
    id: 9,
    nombre: "EJERCICIO DE LA ACCIÓN PENAL",
    fecha: 2014,
    tipo: "Vademecum procesal",
    materia: "Código Integral Penal",
    pdf: bookList["Ebook9"],
  },
  {
    id: 10,
    nombre: "EL TESTIMONIO",
    fecha: 2014,
    tipo: "Vademecum procesal",
    materia: "Código Integral Penal",
    pdf: bookList["Ebook10"],
  },
  {
    id: 11,
    nombre: "EXTINCIÓN DE LA ACCIÓN PENAL",
    fecha: 2014,
    tipo: "Vademecum procesal",
    materia: "Código Integral Penal",
    pdf: bookList["Ebook11"],
  },
  {
    id: 12,
    nombre: "JURISDICCIÓN",
    fecha: 2014,
    tipo: "Vademecum procesal",
    materia: "Código Integral Penal",
    pdf: bookList["Ebook12"],
  },
  {
    id: 13,
    nombre: "LA PERICIA",
    fecha: 2014,
    tipo: "Vademecum procesal",
    materia: "Código Integral Penal",
    pdf: bookList["Ebook13"],
  },
  {
    id: 14,
    nombre: "LA PRUEBA",
    fecha: 2014,
    tipo: "Vademecum procesal",
    materia: "Código Integral Penal",
    pdf: bookList["Ebook14"],
  },
  {
    id: 15,
    nombre: "LA VÍCTIMA",
    fecha: 2014,
    tipo: "Vademecum procesal",
    materia: "Código Integral Penal",
    pdf: bookList["Ebook15"],
  },
  {
    id: 16,
    nombre: "LAS AUDIENCIAS",
    fecha: 2014,
    tipo: "Vademecum procesal",
    materia: "Código Integral Penal",
    pdf: bookList["Ebook16"],
  },
  {
    id: 17,
    nombre: "MEDIDAS CAUTELARES SOBRE BIENES",
    fecha: 2014,
    tipo: "Vademecum procesal",
    materia: "Código Integral Penal",
    pdf: bookList["Ebook17"],
  },
  {
    id: 18,
    nombre: "MEDIDAS DE PROTECCIÓN",
    fecha: 2014,
    tipo: "Vademecum procesal",
    materia: "Código Integral Penal",
    pdf: bookList["Ebook18"],
  },
  {
    id: 19,
    nombre: "MEDIOS DE COMUNICACIÓN",
    fecha: 2014,
    tipo: "Vademecum procesal",
    materia: "Código Integral Penal",
    pdf: bookList["Ebook19"],
  },
  {
    id: 20,
    nombre: "ORALIDAD",
    fecha: 2014,
    tipo: "Vademecum procesal",
    materia: "Código Integral Penal",
    pdf: bookList["Ebook20"],
  },
  {
    id: 21,
    nombre: "PRESCRIPCIÓN DEL EJERCICIO DE LA ACCIÓN PENAL",
    fecha: 2014,
    tipo: "Vademecum procesal",
    materia: "Código Integral Penal",
    pdf: bookList["Ebook21"],
  },
  {
    id: 22,
    nombre: "PRISIÓN PREVENTIVA",
    fecha: 2014,
    tipo: "Vademecum procesal",
    materia: "Código Integral Penal",
    pdf: bookList["Ebook22"],
  },
  {
    id: 23,
    nombre: "PRUEBA DOCUMENTAL",
    fecha: 2014,
    tipo: "Vademecum procesal",
    materia: "Código Integral Penal",
    pdf: bookList["Ebook23"],
  },
  {
    id: 24,
    nombre: "RECONOCIMIENTO DEL LUGAR DE LOS HECHOS",
    fecha: 2014,
    tipo: "Vademecum procesal",
    materia: "Código Integral Penal",
    pdf: bookList["Ebook24"],
  },
  {
    id: 25,
    nombre: "REGISTRO DE VEHÍCULOS",
    fecha: 2014,
    tipo: "Vademecum procesal",
    materia: "Código Integral Penal",
    pdf: bookList["Ebook25"],
  },
  {
    id: 26,
    nombre: "REGISTROS",
    fecha: 2014,
    tipo: "Vademecum procesal",
    materia: "Código Integral Penal",
    pdf: bookList["Ebook26"],
  },
  {
    id: 27,
    nombre: "SUJETOS PROCESALES",
    fecha: 2014,
    tipo: "Vademecum procesal",
    materia: "Código Integral Penal",
    pdf: bookList["Ebook27"],
  },
  {
    id: 28,
    nombre: "TESTIMONIO DE LA VÍCTIMA",
    fecha: 2014,
    tipo: "Vademecum procesal",
    materia: "Código Integral Penal",
    pdf: bookList["Ebook28"],
  },
  {
    id: 29,
    nombre: "TESTIMONIO DE TERCEROS",
    fecha: 2014,
    tipo: "Vademecum procesal",
    materia: "Código Integral Penal",
    pdf: bookList["Ebook29"],
  },
  {
    id: 30,
    nombre: "ACCIONES EN PROCEDIMIENTO CONTENCIOSO ADMINISTRATIVO",
    fecha: 2016,
    tipo: "Vademecum procesal",
    materia: "Código Orgánico General de Procesos (COGEP)",
    pdf: bookList["Ebook30"],
  },
  {
    id: 31,
    nombre: "ACUMULACIÓN DE PROCESOS",
    fecha: 2016,
    tipo: "Vademecum procesal",
    materia: "Código Orgánico General de Procesos (COGEP)",
    pdf: bookList["Ebook31"],
  },
  {
    id: 32,
    nombre: "APREMIOS",
    fecha: 2016,
    tipo: "Vademecum procesal",
    materia: "Código Orgánico General de Procesos (COGEP)",
    pdf: bookList["Ebook32"],
  },
  {
    id: 33,
    nombre: "AUDIENCIA DE EJECUCIÓN",
    fecha: 2016,
    tipo: "Vademecum procesal",
    materia: "Código Orgánico General de Procesos (COGEP)",
    pdf: bookList["Ebook33"],
  },
  {
    id: 34,
    nombre: "AUDIENCIA",
    fecha: 2016,
    tipo: "Vademecum procesal",
    materia: "Código Orgánico General de Procesos (COGEP)",
    pdf: bookList["Ebook34"],
  },
  {
    id: 35,
    nombre: "CALIFICACIÓN DE LA DEMANDA",
    fecha: 2016,
    tipo: "Vademecum procesal",
    materia: "Código Orgánico General de Procesos (COGEP)",
    pdf: bookList["Ebook35"],
  },
  {
    id: 36,
    nombre: "CITACIÓN",
    fecha: 2016,
    tipo: "Vademecum procesal",
    materia: "Código Orgánico General de Procesos (COGEP)",
    pdf: bookList["Ebook36"],
  },
  {
    id: 37,
    nombre: "COMPETENCIA",
    fecha: 2016,
    tipo: "Vademecum procesal",
    materia: "Código Orgánico General de Procesos (COGEP)",
    pdf: bookList["Ebook37"],
  },
  {
    id: 38,
    nombre: "CONTENIDO DE LA DEMANDA",
    fecha: 2016,
    tipo: "Vademecum procesal",
    materia: "Código Orgánico General de Procesos (COGEP)",
    pdf: bookList["Ebook38"],
  },
  {
    id: 39,
    nombre: "CONTENIDO DEL INFORME PERICIAL",
    fecha: 2016,
    tipo: "Vademecum procesal",
    materia: "Código Orgánico General de Procesos (COGEP)",
    pdf: bookList["Ebook39"],
  },
  {
    id: 40,
    nombre: "CONTESTACIÓN A LA DEMANDA",
    fecha: 2016,
    tipo: "Vademecum procesal",
    materia: "Código Orgánico General de Procesos (COGEP)",
    pdf: bookList["Ebook40"],
  },
  {
    id: 41,
    nombre: "COSTAS",
    fecha: 2016,
    tipo: "Vademecum procesal",
    materia: "Código Orgánico General de Procesos (COGEP)",
    pdf: bookList["Ebook41"],
  },
  {
    id: 42,
    nombre: "DECLARACIÓN DE PARTE",
    fecha: 2016,
    tipo: "Vademecum procesal",
    materia: "Código Orgánico General de Procesos (COGEP)",
    pdf: bookList["Ebook42"],
  },
  {
    id: 43,
    nombre: "DECLARACIÓN DE TESTIGOS",
    fecha: 2016,
    tipo: "Vademecum procesal",
    materia: "Código Orgánico General de Procesos (COGEP)",
    pdf: bookList["Ebook43"],
  },
  {
    id: 44,
    nombre: "DETERMINACIÓN DE LA CUANTÍA",
    fecha: 2016,
    tipo: "Vademecum procesal",
    materia: "Código Orgánico General de Procesos (COGEP)",
    pdf: bookList["Ebook44"],
  },
  {
    id: 45,
    nombre: "DILIGENCIAS PREPARATORIAS",
    fecha: 2016,
    tipo: "Vademecum procesal",
    materia: "Código Orgánico General de Procesos (COGEP)",
    pdf: bookList["Ebook45"],
  },
  {
    id: 46,
    nombre:
      "DISPOSICIONES COMUNES A LOS PROCESOS CONTENCIOSO TRIBUTARIO Y ADMINISTRATIVO",
    fecha: 2016,
    tipo: "Vademecum procesal",
    materia: "Código Orgánico General de Procesos (COGEP)",
    pdf: bookList["Ebook46"],
  },
  {
    id: 47,
    nombre: "DISPOSICIONES PRELIMINARES",
    fecha: 2016,
    tipo: "Vademecum procesal",
    materia: "Código Orgánico General de Procesos (COGEP)",
    pdf: bookList["Ebook47"],
  },
  {
    id: 48,
    nombre: "DOCUMENTO PRIVADO",
    fecha: 2016,
    tipo: "Vademecum procesal",
    materia: "Código Orgánico General de Procesos (COGEP)",
    pdf: bookList["Ebook48"],
  },
  {
    id: 49,
    nombre: "DOCUMENTO PÚBLICO",
    fecha: 2016,
    tipo: "Vademecum procesal",
    materia: "Código Orgánico General de Procesos (COGEP)",
    pdf: bookList["Ebook49"],
  },
  {
    id: 50,
    nombre: "DOCUMENTOS QUE ACOMPAÑAN A LA DEMANDA",
    fecha: 2016,
    tipo: "Vademecum procesal",
    materia: "Código Orgánico General de Procesos (COGEP)",
    pdf: bookList["Ebook50"],
  },
  {
    id: 51,
    nombre: "EJECUCIÓN DE ACCIONES DE DAR, HACER O NO HACER",
    fecha: 2016,
    tipo: "Vademecum procesal",
    materia: "Código Orgánico General de Procesos (COGEP)",
    pdf: bookList["Ebook51"],
  },
  {
    id: 52,
    nombre: "EJECUCIÓN",
    fecha: 2016,
    tipo: "Vademecum procesal",
    materia: "Código Orgánico General de Procesos (COGEP)",
    pdf: bookList["Ebook52"],
  },
  {
    id: 53,
    nombre: "ETAPAS DE LA DEMANDA",
    fecha: 2016,
    tipo: "Vademecum procesal",
    materia: "Código Orgánico General de Procesos (COGEP)",
    pdf: bookList["Ebook53"],
  },
  {
    id: 54,
    nombre: "EXCUSA Y RECUSACIÓN",
    fecha: 2016,
    tipo: "Vademecum procesal",
    materia: "Código Orgánico General de Procesos (COGEP)",
    pdf: bookList["Ebook54"],
  },
  {
    id: 55,
    nombre: "FORMAS EXTRAORDINARIAS DE CONCLUSIÓN DEL PROCESO",
    fecha: 2016,
    tipo: "Vademecum procesal",
    materia: "Código Orgánico General de Procesos (COGEP)",
    pdf: bookList["Ebook55"],
  },
  {
    id: 56,
    nombre: "IMPUGNACIÓN",
    fecha: 2016,
    tipo: "Vademecum procesal",
    materia: "Código Orgánico General de Procesos (COGEP)",
    pdf: bookList["Ebook56"],
  },
  {
    id: 57,
    nombre: "INSPECCIÓN JUDICIAL",
    fecha: 2016,
    tipo: "Vademecum procesal",
    materia: "Código Orgánico General de Procesos (COGEP)",
    pdf: bookList["Ebook57"],
  },
  {
    id: 58,
    nombre: "PLURIDAD DE PRETENSIONES",
    fecha: 2016,
    tipo: "Vademecum procesal",
    materia: "Código Orgánico General de Procesos (COGEP)",
    pdf: bookList["Ebook58"],
  },
  {
    id: 59,
    nombre: "PROCEDIMIENTO CONCURSAL",
    fecha: 2016,
    tipo: "Vademecum procesal",
    materia: "Código Orgánico General de Procesos (COGEP)",
    pdf: bookList["Ebook59"],
  },
  {
    id: 60,
    nombre: "PROCEDIMIENTO CONTENCIOSO ADMINISTRATIVO",
    fecha: 2016,
    tipo: "Vademecum procesal",
    materia: "Código Orgánico General de Procesos (COGEP)",
    pdf: bookList["Ebook60"],
  },
  {
    id: 61,
    nombre: "PROCEDIMIENTO CONTENCIOSO TRIBUTARIO",
    fecha: 2016,
    tipo: "Vademecum procesal",
    materia: "Código Orgánico General de Procesos (COGEP)",
    pdf: bookList["Ebook61"],
  },
  {
    id: 62,
    nombre: "PROCEDIMIENTO DE EXCEPCIONES A LA COACTIVA",
    fecha: 2016,
    tipo: "Vademecum procesal",
    materia: "Código Orgánico General de Procesos (COGEP)",
    pdf: bookList["Ebook62"],
  },
  {
    id: 63,
    nombre: "PROCEDIMIENTO EJECUTIVO",
    fecha: 2016,
    tipo: "Vademecum procesal",
    materia: "Código Orgánico General de Procesos (COGEP)",
    pdf: bookList["Ebook63"],
  },
  {
    id: 64,
    nombre: "PROCEDIMIENTO MONITORIO",
    fecha: 2016,
    tipo: "Vademecum procesal",
    materia: "Código Orgánico General de Procesos (COGEP)",
    pdf: bookList["Ebook64"],
  },
  {
    id: 65,
    nombre: "PROCEDIMIENTO ORDINARIO ANUNCIACION DE LA PRUEBA",
    fecha: 2016,
    tipo: "Vademecum procesal",
    materia: "Código Orgánico General de Procesos (COGEP)",
    pdf: bookList["Ebook65"],
  },
  {
    id: 66,
    nombre: "PROCEDIMIENTO ORDINARIO AUDIENCIA DE JUICIO",
    fecha: 2016,
    tipo: "Vademecum procesal",
    materia: "Código Orgánico General de Procesos (COGEP)",
    pdf: bookList["Ebook66"],
  },
  {
    id: 67,
    nombre: "PROCEDIMIENTO ORDINARIO AUDIENCIA PRELIMINAR",
    fecha: 2016,
    tipo: "Vademecum procesal",
    materia: "Código Orgánico General de Procesos (COGEP)",
    pdf: bookList["Ebook67"],
  },
  {
    id: 68,
    nombre: "PROCEDIMIENTO SUMARIO",
    fecha: 2016,
    tipo: "Vademecum procesal",
    materia: "Código Orgánico General de Procesos (COGEP)",
    pdf: bookList["Ebook68"],
  },
  {
    id: 69,
    nombre: "PROCEDIMIENTO VOLUNTARIO",
    fecha: 2016,
    tipo: "Vademecum procesal",
    materia: "Código Orgánico General de Procesos (COGEP)",
    pdf: bookList["Ebook69"],
  },
  {
    id: 70,
    nombre: "PROCURACIÓN JUDICIAL",
    fecha: 2016,
    tipo: "Vademecum procesal",
    materia: "Código Orgánico General de Procesos (COGEP)",
    pdf: bookList["Ebook70"],
  },
  {
    id: 71,
    nombre: "PROVIDENCIAS JUDICIALES",
    fecha: 2016,
    tipo: "Vademecum procesal",
    materia: "Código Orgánico General de Procesos (COGEP)",
    pdf: bookList["Ebook71"],
  },
  {
    id: 72,
    nombre: "PROVIDENCIAS PREVENTIVAS",
    fecha: 2016,
    tipo: "Vademecum procesal",
    materia: "Código Orgánico General de Procesos (COGEP)",
    pdf: bookList["Ebook72"],
  },
  {
    id: 73,
    nombre: "PRUEBA DOCUMENTAL",
    fecha: 2016,
    tipo: "Vademecum procesal",
    materia: "Código Orgánico General de Procesos (COGEP)",
    pdf: bookList["Ebook73"],
  },
  {
    id: 74,
    nombre: "PRUEBA PERICIAL",
    fecha: 2016,
    tipo: "Vademecum procesal",
    materia: "Código Orgánico General de Procesos (COGEP)",
    pdf: bookList["Ebook74"],
  },
  {
    id: 75,
    nombre: "PRUEBA TESTIMONIAL",
    fecha: 2016,
    tipo: "Vademecum procesal",
    materia: "Código Orgánico General de Procesos (COGEP)",
    pdf: bookList["Ebook75"],
  },
  {
    id: 76,
    nombre: "RECURSO DE APELACIÓN",
    fecha: 2016,
    tipo: "Vademecum procesal",
    materia: "Código Orgánico General de Procesos (COGEP)",
    pdf: bookList["Ebook76"],
  },
  {
    id: 77,
    nombre: "RECURSO DE CASACIÓN",
    fecha: 2016,
    tipo: "Vademecum procesal",
    materia: "Código Orgánico General de Procesos (COGEP)",
    pdf: bookList["Ebook77"],
  },
  {
    id: 78,
    nombre: "RECURSO DE HECHO",
    fecha: 2016,
    tipo: "Vademecum procesal",
    materia: "Código Orgánico General de Procesos (COGEP)",
    pdf: bookList["Ebook78"],
  },
  {
    id: 79,
    nombre: "RENUNCIA A LA PROCURACION JUDICIAL",
    fecha: 2016,
    tipo: "Vademecum procesal",
    materia: "Código Orgánico General de Procesos (COGEP)",
    pdf: bookList["Ebook79"],
  },
  {
    id: 80,
    nombre: "SOLEMNIDADES SUSTANCIALES",
    fecha: 2016,
    tipo: "Vademecum procesal",
    materia: "Código Orgánico General de Procesos (COGEP)",
    pdf: bookList["Ebook80"],
  },
  {
    id: 81,
    nombre: "SUJETOS PROCESALES",
    fecha: 2016,
    tipo: "Vademecum procesal",
    materia: "Código Orgánico General de Procesos (COGEP)",
    pdf: bookList["Ebook81"],
  },
  {
    id: 82,
    nombre: "TERCERÍAS",
    fecha: 2016,
    tipo: "Vademecum procesal",
    materia: "Código Orgánico General de Procesos (COGEP)",
    pdf: bookList["Ebook82"],
  },
  {
    id: 83,
    nombre: "TÉRMINOS",
    fecha: 2016,
    tipo: "Vademecum procesal",
    materia: "Código Orgánico General de Procesos (COGEP)",
    pdf: bookList["Ebook83"],
  },
  {
    id: 84,
    nombre: "ACUMULACIÓN DE DATOS",
    fecha: 2011,
    tipo: "Vademecum procesal",
    materia: "Procedimiento Civil",
    pdf: bookList["Ebook84"],
  },
  {
    id: 85,
    nombre: "APERTURA DE LA SUCESIÓN HEREDITARIA",
    fecha: 2011,
    tipo: "Vademecum procesal",
    materia: "Procedimiento Civil",
    pdf: bookList["Ebook85"],
  },
  {
    id: 86,
    nombre: "APERTURA Y PUBLICACIÓN DE TESTAMENTO CERRADO",
    fecha: 2011,
    tipo: "Vademecum procesal",
    materia: "Procedimiento Civil",
    pdf: bookList["Ebook86"],
  },
  {
    id: 87,
    nombre: "APREMIOS",
    fecha: 2011,
    tipo: "Vademecum procesal",
    materia: "Procedimiento Civil",
    pdf: bookList["Ebook87"],
  },
  {
    id: 88,
    nombre: "AUTOS Y DECRETOS",
    fecha: 2011,
    tipo: "Vademecum procesal",
    materia: "Procedimiento Civil",
    pdf: bookList["Ebook88"],
  },
  {
    id: 89,
    nombre: "CESIÓN DE BIENES",
    fecha: 2011,
    tipo: "Vademecum procesal",
    materia: "Procedimiento Civil",
    pdf: bookList["Ebook89"],
  },
  {
    id: 90,
    nombre: "CITACIÓN",
    fecha: 2011,
    tipo: "Vademecum procesal",
    materia: "Procedimiento Civil",
    pdf: bookList["Ebook90"],
  },
  {
    id: 91,
    nombre: "COMPETENCIA",
    fecha: 2011,
    tipo: "Vademecum procesal",
    materia: "Procedimiento Civil",
    pdf: bookList["Ebook91"],
  },
  {
    id: 92,
    nombre: "CONCURSO DE ACREEDORES (QUIEBRA)",
    fecha: 2011,
    tipo: "Vademecum procesal",
    materia: "Procedimiento Civil",
    pdf: bookList["Ebook92"],
  },
  {
    id: 93,
    nombre: "CONTESTACIÓN DE LA DEMANDA",
    fecha: 2011,
    tipo: "Vademecum procesal",
    materia: "Procedimiento Civil",
    pdf: bookList["Ebook93"],
  },
  {
    id: 94,
    nombre: "CONVENIO (QUIEBRA)",
    fecha: 2011,
    tipo: "Vademecum procesal",
    materia: "Procedimiento Civil",
    pdf: bookList["Ebook94"],
  },
  {
    id: 95,
    nombre: "DEMANDA",
    fecha: 2011,
    tipo: "Vademecum procesal",
    materia: "Procedimiento Civil",
    pdf: bookList["Ebook95"],
  },
  {
    id: 96,
    nombre: "DESESTIMIENTO",
    fecha: 2011,
    tipo: "Vademecum procesal",
    materia: "Procedimiento Civil",
    pdf: bookList["Ebook96"],
  },
  {
    id: 97,
    nombre: "DISOLUCIÓN CONSENSUAL DE LA SOCIEDAD CONYUGAL",
    fecha: 2011,
    tipo: "Vademecum procesal",
    materia: "Procedimiento Civil",
    pdf: bookList["Ebook97"],
  },
  {
    id: 98,
    nombre: "EXCEPCIONES",
    fecha: 2011,
    tipo: "Vademecum procesal",
    materia: "Procedimiento Civil",
    pdf: bookList["Ebook98"],
  },
  {
    id: 99,
    nombre: "FASE DE EJECUCIÓN DE LA SENTENCIA",
    fecha: 2011,
    tipo: "Vademecum procesal",
    materia: "Procedimiento Civil",
    pdf: bookList["Ebook99"],
  },
  {
    id: 100,
    nombre: "INSOLVENCIA",
    fecha: 2011,
    tipo: "Vademecum procesal",
    materia: "Procedimiento Civil",
    pdf: bookList["Ebook100"],
  },
  {
    id: 101,
    nombre: "JUICIO COACTIVO",
    fecha: 2011,
    tipo: "Vademecum procesal",
    materia: "Procedimiento Civil",
    pdf: bookList["Ebook101"],
  },
  {
    id: 102,
    nombre: "JUICIO CONTRA EL DESPOJO VIOLENTO",
    fecha: 2011,
    tipo: "Vademecum procesal",
    materia: "Procedimiento Civil",
    pdf: bookList["Ebook102"],
  },
  {
    id: 103,
    nombre: "JUICIO DE ALIMENTOS",
    fecha: 2011,
    tipo: "Vademecum procesal",
    materia: "Procedimiento Civil",
    pdf: bookList["Ebook103"],
  },
  {
    id: 104,
    nombre: "JUICIO DE COMPETENCIA",
    fecha: 2011,
    tipo: "Vademecum procesal",
    materia: "Procedimiento Civil",
    pdf: bookList["Ebook104"],
  },
  {
    id: 105,
    nombre: "JUICIO DE CUENTAS SIN TÍTULO EJECUTIVO",
    fecha: 2011,
    tipo: "Vademecum procesal",
    materia: "Procedimiento Civil",
    pdf: bookList["Ebook105"],
  },
  {
    id: 106,
    nombre: "JUICIO DE DECLARACIÓN DE HERENCIA YACENTE",
    fecha: 2011,
    tipo: "Vademecum procesal",
    materia: "Procedimiento Civil",
    pdf: bookList["Ebook106"],
  },
  {
    id: 107,
    nombre: "JUICIO DE DEMARCACIÓN Y LINDEROS",
    fecha: 2011,
    tipo: "Vademecum procesal",
    materia: "Procedimiento Civil",
    pdf: bookList["Ebook107"],
  },
  {
    id: 108,
    nombre: "JUICIO DE DISENSO DE MATRIMONIO DE MENORES",
    fecha: 2011,
    tipo: "Vademecum procesal",
    materia: "Procedimiento Civil",
    pdf: bookList["Ebook108"],
  },
  {
    id: 109,
    nombre: "JUICIO DE EMANCIPACIÓN VOLUNTARIA",
    fecha: 2011,
    tipo: "Vademecum procesal",
    materia: "Procedimiento Civil",
    pdf: bookList["Ebook109"],
  },
  {
    id: 110,
    nombre: "JUICIO DE EXCUSA",
    fecha: 2011,
    tipo: "Vademecum procesal",
    materia: "Procedimiento Civil",
    pdf: bookList["Ebook110"],
  },
  {
    id: 111,
    nombre: "JUICIO DE EXHIBICIÓN",
    fecha: 2011,
    tipo: "Vademecum procesal",
    materia: "Procedimiento Civil",
    pdf: bookList["Ebook111"],
  },
  {
    id: 112,
    nombre: "JUICIO DE EXPROPIACIÓN",
    fecha: 2011,
    tipo: "Vademecum procesal",
    materia: "Procedimiento Civil",
    pdf: bookList["Ebook112"],
  },
  {
    id: 113,
    nombre: "JUICIO DE FILIACIÓN Y ESTADO CIVIL",
    fecha: 2011,
    tipo: "Vademecum procesal",
    materia: "Procedimiento Civil",
    pdf: bookList["Ebook113"],
  },
  {
    id: 114,
    nombre: "JUICIO DE INCAPACIDAD DE GUARDADORES",
    fecha: 2011,
    tipo: "Vademecum procesal",
    materia: "Procedimiento Civil",
    pdf: bookList["Ebook114"],
  },
  {
    id: 115,
    nombre: "JUICIO DE INTERDICCIÓN DE DISIPADOR",
    fecha: 2011,
    tipo: "Vademecum procesal",
    materia: "Procedimiento Civil",
    pdf: bookList["Ebook115"],
  },
  {
    id: 116,
    nombre: "JUICIO DE INTERDICCIÓN Y REHABILITACIÓN DEL DEMENTE",
    fecha: 2011,
    tipo: "Vademecum procesal",
    materia: "Procedimiento Civil",
    pdf: bookList["Ebook116"],
  },
  {
    id: 117,
    nombre: "JUICIO DE INVENTARIOS",
    fecha: 2011,
    tipo: "Vademecum procesal",
    materia: "Procedimiento Civil",
    pdf: bookList["Ebook117"],
  },
  {
    id: 118,
    nombre: "JUICIO DE LIQUIDACIÓN Y COBRO DE COSTAS",
    fecha: 2011,
    tipo: "Vademecum procesal",
    materia: "Procedimiento Civil",
    pdf: bookList["Ebook118"],
  },
  {
    id: 119,
    nombre: "JUICIO DE NOMBRAMIENTO DE ADMINISTRADOR DE BIENES HEREDITARIOS",
    fecha: 2011,
    tipo: "Vademecum procesal",
    materia: "Procedimiento Civil",
    pdf: bookList["Ebook119"],
  },
  {
    id: 120,
    nombre: "JUICIO DE NOMBRAMIENTO DE GUARDADORES",
    fecha: 2011,
    tipo: "Vademecum procesal",
    materia: "Procedimiento Civil",
    pdf: bookList["Ebook120"],
  },
  {
    id: 121,
    nombre: "JUICIO DE PARTICIÓN",
    fecha: 2011,
    tipo: "Vademecum procesal",
    materia: "Procedimiento Civil",
    pdf: bookList["Ebook121"],
  },
  {
    id: 122,
    nombre: "JUICIO DE RECUSACIÓN",
    fecha: 2011,
    tipo: "Vademecum procesal",
    materia: "Procedimiento Civil",
    pdf: bookList["Ebook122"],
  },
  {
    id: 123,
    nombre: "JUICIO DE SERVIDUMBRE",
    fecha: 2011,
    tipo: "Vademecum procesal",
    materia: "Procedimiento Civil",
    pdf: bookList["Ebook123"],
  },
  {
    id: 124,
    nombre: "JUICIO EJECUTIVO",
    fecha: 2011,
    tipo: "Vademecum procesal",
    materia: "Procedimiento Civil",
    pdf: bookList["Ebook124"],
  },
  {
    id: 125,
    nombre: "JUICIO ORDINARIO",
    fecha: 2011,
    tipo: "Vademecum procesal",
    materia: "Procedimiento Civil",
    pdf: bookList["Ebook125"],
  },
  {
    id: 126,
    nombre: "JUICIO VERBAL SUMARIO DE OBRA NUEVA",
    fecha: 2011,
    tipo: "Vademecum procesal",
    materia: "Procedimiento Civil",
    pdf: bookList["Ebook126"],
  },
  {
    id: 127,
    nombre: "JUICIO VERBAL SUMARIO",
    fecha: 2011,
    tipo: "Vademecum procesal",
    materia: "Procedimiento Civil",
    pdf: bookList["Ebook127"],
  },
  {
    id: 128,
    nombre: "JURISDICCIÓN",
    fecha: 2011,
    tipo: "Vademecum procesal",
    materia: "Procedimiento Civil",
    pdf: bookList["Ebook128"],
  },
  {
    id: 129,
    nombre: "LIQUIDACIÓN DE LA MASA EN FALTA DE CONVENIO",
    fecha: 2011,
    tipo: "Vademecum procesal",
    materia: "Procedimiento Civil",
    pdf: bookList["Ebook129"],
  },
  {
    id: 130,
    nombre: "LIQUIDACIÓN DE LA SOCIEDAD CONYUGAL",
    fecha: 2011,
    tipo: "Vademecum procesal",
    materia: "Procedimiento Civil",
    pdf: bookList["Ebook130"],
  },
  {
    id: 131,
    nombre: "LOS JUICIOS EN GENERAL",
    fecha: 2011,
    tipo: "Vademecum procesal",
    materia: "Procedimiento Civil",
    pdf: bookList["Ebook131"],
  },
  {
    id: 132,
    nombre: "NOMBRAMIENTO DE CURADOR AD LITEM",
    fecha: 2011,
    tipo: "Vademecum procesal",
    materia: "Procedimiento Civil",
    pdf: bookList["Ebook132"],
  },
  {
    id: 133,
    nombre: "NOMBRAMIENTO DE CURADOR DEL NONATO",
    fecha: 2011,
    tipo: "Vademecum procesal",
    materia: "Procedimiento Civil",
    pdf: bookList["Ebook133"],
  },
  {
    id: 134,
    nombre: "NOTIFICACIÓN",
    fecha: 2011,
    tipo: "Vademecum procesal",
    materia: "Procedimiento Civil",
    pdf: bookList["Ebook134"],
  },
  {
    id: 135,
    nombre: "NULIDAD Y RESOLUCIÓN DE CONVENIO",
    fecha: 2011,
    tipo: "Vademecum procesal",
    materia: "Procedimiento Civil",
    pdf: bookList["Ebook135"],
  },
  {
    id: 136,
    nombre: "NULIDAD PROCESALES",
    fecha: 2011,
    tipo: "Vademecum procesal",
    materia: "Procedimiento Civil",
    pdf: bookList["Ebook136"],
  },
  {
    id: 137,
    nombre: "PERSONAS QUE INTERVIENEN EN LOS JUICIOS",
    fecha: 2011,
    tipo: "Vademecum procesal",
    materia: "Procedimiento Civil",
    pdf: bookList["Ebook137"],
  },
  {
    id: 138,
    nombre: "PROTOCOLIZACIÓN DE TESTAMENTO ABIERTO",
    fecha: 2011,
    tipo: "Vademecum procesal",
    materia: "Procedimiento Civil",
    pdf: bookList["Ebook138"],
  },
  {
    id: 139,
    nombre: "PROVIDENCIAS PREVENTIVAS",
    fecha: 2011,
    tipo: "Vademecum procesal",
    materia: "Procedimiento Civil",
    pdf: bookList["Ebook139"],
  },
  {
    id: 140,
    nombre: "PRUEBAS",
    fecha: 2011,
    tipo: "Vademecum procesal",
    materia: "Procedimiento Civil",
    pdf: bookList["Ebook140"],
  },
  {
    id: 141,
    nombre: "RECURSO DE APELACIÓN",
    fecha: 2011,
    tipo: "Vademecum procesal",
    materia: "Procedimiento Civil",
    pdf: bookList["Ebook141"],
  },
  {
    id: 142,
    nombre: "RECURSO DE HECHO",
    fecha: 2011,
    tipo: "Vademecum procesal",
    materia: "Procedimiento Civil",
    pdf: bookList["Ebook142"],
  },
  {
    id: 143,
    nombre: "RECURSOS",
    fecha: 2011,
    tipo: "Vademecum procesal",
    materia: "Procedimiento Civil",
    pdf: bookList["Ebook143"],
  },
  {
    id: 144,
    nombre: "REHABILITACIÓN",
    fecha: 2011,
    tipo: "Vademecum procesal",
    materia: "Procedimiento Civil",
    pdf: bookList["Ebook144"],
  },
  {
    id: 145,
    nombre: "SENTENCIA",
    fecha: 2011,
    tipo: "Vademecum procesal",
    materia: "Procedimiento Civil",
    pdf: bookList["Ebook145"],
  },
  {
    id: 146,
    nombre: "TERCERÍA COADYUVANTE EN JUICIO EJECUTIVO",
    fecha: 2011,
    tipo: "Vademecum procesal",
    materia: "Procedimiento Civil",
    pdf: bookList["Ebook146"],
  },
  {
    id: 147,
    nombre: "TERCERÍA EXCLUYENTE EN JUICIO EJECUTIVO",
    fecha: 2011,
    tipo: "Vademecum procesal",
    materia: "Procedimiento Civil",
    pdf: bookList["Ebook147"],
  },
  {
    id: 148,
    nombre: "TERCERÍAS EN JUICIO ORDINARIO",
    fecha: 2011,
    tipo: "Vademecum procesal",
    materia: "Procedimiento Civil",
    pdf: bookList["Ebook148"],
  },
  {
    id: 149,
    nombre: "TERCERÍAS",
    fecha: 2011,
    tipo: "Vademecum procesal",
    materia: "Procedimiento Civil",
    pdf: bookList["Ebook149"],
  },
  {
    id: 150,
    nombre: "TÉRMINOS",
    fecha: 2011,
    tipo: "Vademecum procesal",
    materia: "Procedimiento Civil",
    pdf: bookList["Ebook150"],
  },
  {
    id: 151,
    nombre: "FASE PREPROCESAL DE INDAGACION PREVIA",
    fecha: 2011,
    tipo: "Vademecum procesal",
    materia: "Procedimiento Penal",
    pdf: bookList["Ebook151"],
  },
  {
    id: 152,
    nombre: "ETAPA DE JUICIO",
    fecha: 2011,
    tipo: "Vademecum procesal",
    materia: "Procedimiento Penal",
    pdf: bookList["Ebook152"],
  },
  {
    id: 153,
    nombre: "ETAPA INTERMEDIA",
    fecha: 2011,
    tipo: "Vademecum procesal",
    materia: "Procedimiento Penal",
    pdf: bookList["Ebook153"],
  },
  {
    id: 154,
    nombre: "FASE PREPROCESAL DE INDAGACION PREVIA",
    fecha: 2011,
    tipo: "Vademecum procesal",
    materia: "Procedimiento Penal",
    pdf: bookList["Ebook154"],
  },
  {
    id: 155,
    nombre: "INSTRUCCIÓN FISCAL",
    fecha: 2011,
    tipo: "Vademecum procesal",
    materia: "Procedimiento Penal",
    pdf: bookList["Ebook155"],
  },
  {
    id: 156,
    nombre: "PROCEDIMIENTO ABREVIADO",
    fecha: 2012,
    tipo: "Vademecum procesal",
    materia: "Procedimiento Penal",
    pdf: bookList["Ebook156"],
  },
  {
    id: 157,
    nombre: "PROCEDIMIENTO DE ACCIÓN PENAL PRIVADA",
    fecha: 2012,
    tipo: "Vademecum procesal",
    materia: "Procedimiento Penal",
    pdf: bookList["Ebook157"],
  },
  {
    id: 158,
    nombre: "PROCEDIMIENTO PARA APLICACIÓN DEL PRINCIPIO DE OPORTUNIDAD",
    fecha: 2012,
    tipo: "Vademecum procesal",
    materia: "Procedimiento Penal",
    pdf: bookList["Ebook158"],
  },
  {
    id: 159,
    nombre: "PROCEDIMIENTO PARA DELITOS COMETIDOS POR MEDIOS DE COMUNICACIÓN",
    fecha: 2012,
    tipo: "Vademecum procesal",
    materia: "Procedimiento Penal",
    pdf: bookList["Ebook159"],
  },
  {
    id: 160,
    nombre: "PROCEDIMIENTO PARA DELITOS FLAGRANTES",
    fecha: 2012,
    tipo: "Vademecum procesal",
    materia: "Procedimiento Penal",
    pdf: bookList["Ebook160"],
  },
  {
    id: 161,
    nombre: "PROCEDIMEINTO PARA JUZGAMIENTO DE CONTRAVENCIONES",
    fecha: 2012,
    tipo: "Vademecum procesal",
    materia: "Procedimiento Penal",
    pdf: bookList["Ebook161"],
  },
];

var libros = books;
export default libros;
