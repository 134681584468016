//Import react library
import React, { Component, Fragment } from "react";
//Import material design components
import { MDBCard, MDBCardBody, MDBCardText, MDBCol, MDBRow } from "mdbreact";

//Import pagination library
import Pagination from "react-paginating";
//Import books
import { books } from "../../assets/data/vademecum/books";
import { FaSearch } from "react-icons/fa";

import Fuse from "fuse.js";

//declare constants
const limit = 12;
const rowsPerPage = limit;
const pageCount = 5;

const options = {
  isCaseSensitive: false,
  includeScore: false,
  shouldSort: true,
  includeMatches: false,
  findAllMatches: true,
  minMatchCharLength: 0,
  // location: 0,
  threshold: 0.2,
  // distance: 100,
  useExtendedSearch: false,
  ignoreLocation: true,
  ignoreFieldNorm: true,
  keys: ["nombre", "fecha", "tipo", "materia"],
};

export default class Modelo extends Component {
  state = {
    data: [], //Stores the result of the alphabetic search
    value: "",
    currentPage: 1, //Value of the current page of the pagination section
    total: 129, //Total pages
    alphabet: "", //Letter selected for alphabetic search
    books: [], //Stores the books information
  };

  componentDidMount() {
    this.setBooks(); //Stores the books data in state
  }
  //Handles the clic on a letter form alphabetic toolbar
  onAlphabetClick = (e) => {
    //Saves the selected letter in state
    this.setState({ alphabet: e.target.value });

    let result = [];
    //load books state
    const { books } = this.state;
    //filter data books  according to initial letter search
    if (e.target.value) {
      result = books.filter(
        (element) =>
          element.nombre.charAt(0).toLowerCase() ===
          e.target.value.toLowerCase()
      );
    } else {
      result = books || [];
    }

    //Store the result from the search in state
    this.setState(() => {
      return { data: result, value: "" };
    });
    //result = result.map((item) => (<li>{item.nombre}</li>))
    //return result;
  };
  //Shows alphabetic toolbar
  prepareAlphabets = () => {
    let result = [];
    for (let i = 65; i < 91; i++) {
      result.push(
        <button
          className="AlphabetBtn px-3"
          type="button"
          key={i}
          onClick={this.onAlphabetClick}
          value={String.fromCharCode(i)}
        >
          {String.fromCharCode(i)}
        </button>
      );
    }
    return result;
  };

  //Handles page change triggered by pagination buttons
  handlePageChange = (page, e) => {
    this.setState({
      currentPage: page,
    });
  };

  //Stores books in state
  setBooks = () => {
    let tempBooks = [];

    books.forEach((item) => {
      const singleItem = { ...item };
      tempBooks = [...tempBooks, singleItem];
    });

    const fuse = new Fuse(tempBooks, options);
    var fuseResult = fuse.search(" ");

    console.log(fuseResult);
    this.setState(() => {
      return {
        books: tempBooks,
        data: fuseResult,
        total: tempBooks.length + 1,
      };
    });
  };
  //Handles page change triggered by input
  handleChange = (event) => {
    if (event.target.value !== "") {
      const fuse = new Fuse(this.state.books, options);
      var fuseResult = fuse.search(event.target.value);
      console.log("fuse reults", fuseResult);

      this.setState(() => {
        return { data: fuseResult };
      });
    }
    const { value } = event.target;
    this.setState({ value });
  };
  render() {
    const { data, value, currentPage } = this.state; //retrieves states
    return (
      <MDBCol size="12" className="mx-0 px-2">
        {" "}
        {/*Main container*/}
        <div className="mx-0 px-0 px-md-2 px-lg-3 px-xl-5">
          <MDBRow className="noSpace searchRow mb-5">
            {/*Container for search bar*/}
            <div className="input-group">
              <input
                type="text"
                value={value}
                onChange={this.handleChange}
                className="form-control"
                placeholder="Título, tema, materia, año..."
              />
              <div className="input-group-prepend">
                <span className="input-group-text ZoneBg" id="basic-addon">
                  <FaSearch className="prefix searchIcon ZoneBg"></FaSearch>
                </span>
              </div>
            </div>
          </MDBRow>

          <Fragment>
            <Pagination //pagination component
              className="mt-5 hjh-pagination-container"
              total={data.length}
              limit={limit}
              pageCount={pageCount}
              currentPage={currentPage}
            >
              {({
                pages,
                currentPage,
                hasNextPage,
                hasPreviousPage,
                totalPages,
                getPageItemProps,
              }) => (
                <div>
                  <button //First page button
                    className="PaginationBtn px-3 py-2"
                    {...getPageItemProps({
                      pageValue: 1,
                      onPageChange: this.handlePageChange,
                    })}
                  >
                    Primera
                  </button>

                  {hasPreviousPage && (
                    <button //previous page button
                      className="PaginationBtn px-3 py-2"
                      {...getPageItemProps({
                        pageValue: currentPage - 1,
                        onPageChange: this.handlePageChange,
                      })}
                    >
                      {"<"}
                    </button>
                  )}

                  {pages.map((page) => {
                    let activePage = null;
                    if (currentPage === page) {
                      activePage = { backgroundColor: "var(--mainZone)" };
                    }
                    return (
                      <button //pages
                        className="PaginationBtn px-3 py-2"
                        {...getPageItemProps({
                          pageValue: page,
                          key: page,
                          style: activePage,
                          onPageChange: this.handlePageChange,
                        })}
                      >
                        {page}
                      </button>
                    );
                  })}

                  {hasNextPage && (
                    <button //next page button
                      className="PaginationBtn px-3 py-2"
                      {...getPageItemProps({
                        pageValue: currentPage + 1,
                        onPageChange: this.handlePageChange,
                      })}
                    >
                      {">"}
                    </button>
                  )}

                  <button //last page button
                    className="PaginationBtn px-3 py-2"
                    {...getPageItemProps({
                      pageValue: totalPages,
                      onPageChange: this.handlePageChange,
                    })}
                  >
                    Última
                  </button>
                </div>
              )}
            </Pagination>

            <MDBRow className="noSpace">
              {" "}
              {/*List of books*/}
              {data
                .slice(
                  (currentPage - 1) * rowsPerPage,
                  (currentPage - 1) * rowsPerPage + rowsPerPage
                )
                .map((el) => (
                  <MDBCol size="12" md="6" lg="4">
                    <MDBCard className="my-4">
                      <MDBCardBody>
                        <p className="hjh-card-title">{el.item.nombre}</p>

                        <p className="hjh-card-subtitle">
                          Año:{" "}
                          <span className="hjh-card-text">{el.item.fecha}</span>
                        </p>
                        <p className="hjh-card-subtitle">
                          Materia:{" "}
                          <span className="hjh-card-text">
                            {el.item.materia}
                          </span>
                        </p>

                        <a
                          href={"files/"+el.item.nombre+".docx"}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="btn EnviarBtn py-2"
                        >
                          Docx
                        </a>
                      </MDBCardBody>
                    </MDBCard>
                  </MDBCol>
                ))}
            </MDBRow>
            <Pagination //Bottom pagination
              className="bg-red pb-3 hjh-pagination-container"
              total={data.length}
              limit={limit}
              pageCount={pageCount}
              currentPage={currentPage}
            >
              {({
                pages,
                currentPage,
                hasNextPage,
                hasPreviousPage,
                totalPages,
                getPageItemProps,
              }) => (
                <div>
                  <button
                    className="PaginationBtn px-3 py-2"
                    {...getPageItemProps({
                      pageValue: 1,
                      onPageChange: this.handlePageChange,
                    })}
                  >
                    Primera
                  </button>

                  {hasPreviousPage && (
                    <button
                      className="PaginationBtn px-3 py-2"
                      {...getPageItemProps({
                        pageValue: currentPage - 1,
                        onPageChange: this.handlePageChange,
                      })}
                    >
                      {"<"}
                    </button>
                  )}

                  {pages.map((page) => {
                    let activePage = null;
                    if (currentPage === page) {
                      activePage = { backgroundColor: "var(--mainZone)" };
                    }
                    return (
                      <button
                        className="PaginationBtn px-3 py-2"
                        {...getPageItemProps({
                          pageValue: page,
                          key: page,
                          style: activePage,
                          onPageChange: this.handlePageChange,
                        })}
                      >
                        {page}
                      </button>
                    );
                  })}

                  {hasNextPage && (
                    <button
                      className="PaginationBtn px-3 py-2"
                      {...getPageItemProps({
                        pageValue: currentPage + 1,
                        onPageChange: this.handlePageChange,
                      })}
                    >
                      {">"}
                    </button>
                  )}

                  <button
                    className="PaginationBtn px-3 py-2"
                    {...getPageItemProps({
                      pageValue: totalPages,
                      onPageChange: this.handlePageChange,
                    })}
                  >
                    Última
                  </button>
                </div>
              )}
            </Pagination>
          </Fragment>
        </div>
      </MDBCol>
    );
  }
}
