import * as bookList from "./booksList";

export const books = [
  {
    id: 1,
    nombre:
      "COBRO DE CUOTAS DE CONDOMINIO, CLUBS, ENTIDADES EDUCATIVAS Y OTRAS - MONITORIO",
    fecha: 2018,
    tipo: "Modelo",
    materia: "CIVIL",
    pdf: bookList["Ebook1"],
  },
  {
    id: 2,
    nombre: "COBRO DE DEUDA SIN TÍTULO EJECUTIVO -MONITORIO",
    fecha: 2018,
    tipo: "Modelo",
    materia: "CIVIL",
    pdf: bookList["Ebook2"],
  },
  {
    id: 3,
    nombre: "COBRO DE PENSIONES DE ARRENDAMIENTO - INQUILINATO - MONITORIO",
    fecha: 2018,
    tipo: "Modelo",
    materia: "CIVIL",
    pdf: bookList["Ebook3"],
  },
  {
    id: 4,
    nombre: "COBRO LETRA DE CAMBIO - EJECUTIVO",
    fecha: 2018,
    tipo: "Modelo",
    materia: "CIVIL",
    pdf: bookList["Ebook4"],
  },
  {
    id: 5,
    nombre: "COBRO LETRA DE CAMBIO CON PROVIDENCIAS PREVENTIVAS - EJECUTIVO",
    fecha: 2018,
    tipo: "Modelo",
    materia: "CIVIL",
    pdf: bookList["Ebook5"],
  },
  {
    id: 6,
    nombre: "COBRO PAGARE A LA ORDEN - EJECUTIVO",
    fecha: 2018,
    tipo: "Modelo",
    materia: "CIVIL",
    pdf: bookList["Ebook6"],
  },
  {
    id: 7,
    nombre: "EJECUCIÓN DE PRENDA INDUSTRIAL -PROCEDIMIENTO DE EJECUCIÓN",
    fecha: 2018,
    tipo: "Modelo",
    materia: "CIVIL",
    pdf: bookList["Ebook7"],
  },
  {
    id: 8,
    nombre:
      "EJECUCIÓN DE SENTENCIA EJECUTORIADA, LAUDO ARBITRAL, ACTA DE MEDIACIÓN, CONTRATO PRENDARIO O ACTA TRANSACCIONAL, CON OBLIGACIÓN DE  DAR DINERO O BIENES DE GÉNERO -  PROCEDIMIENTO DE EJECUCIÓN",
    fecha: 2018,
    tipo: "Modelo",
    materia: "CIVIL",
    pdf: bookList["Ebook8"],
  },
  {
    id: 9,
    nombre:
      "EJECUCIÓN DE SENTENCIA EJECUTORIADA, LAUDO ARBITRAL, ACTA DE MEDIACIÓN, CONTRATO PRENDARIO O ACTA TRANSACCIONAL, CON OBLIGACIÓN DE HACER - PROCEDIMIENTO DE EJECUCIÓN",
    fecha: 2018,
    tipo: "Modelo",
    materia: "CIVIL",
    pdf: bookList["Ebook9"],
  },
  {
    id: 10,
    nombre:
      "EJECUCIÓN DE SENTENCIA EJECUTORIADA, LAUDO ARBITRAL, ACTA DE MEDIACIÓN, CONTRATO PRENDARIO O ACTA TRANSACCIONAL, CON OBLIGACIÓN DE NO HACER - PROCEDIMIENTO DE EJECUCIÓN",
    fecha: 2018,
    tipo: "Modelo",
    materia: "CIVIL",
    pdf: bookList["Ebook10"],
  },
  {
    id: 11,
    nombre:
      "EJECUCIÓN DE SENTENCIA EJECUTORIADA, LAUDO, ACTA DE MEDIACIÓN, CONTRATO PRENDARIO O ACTA TRANSACCIONAL, CON OBLIGACIÓN DE DAR ESPECIE O CUERPO CIERTO - PROCEDIMIENTO DE EJECUCIÓN",
    fecha: 2018,
    tipo: "Modelo",
    materia: "CIVIL",
    pdf: bookList["Ebook11"],
  },
  {
    id: 12,
    nombre: "INSOLVENCIA – CONCURSO NECESARIO DE ACREEDORES - CONCURSAL",
    fecha: 2018,
    tipo: "Modelo",
    materia: "CIVIL",
    pdf: bookList["Ebook12"],
  },
  {
    id: 13,
    nombre: "OBRA NUEVA - SUMARIO",
    fecha: 2018,
    tipo: "Modelo",
    materia: "CIVIL",
    pdf: bookList["Ebook13"],
  },
  {
    id: 14,
    nombre: "PAGO POR CONSIGNACIÓN - VOLUNTARIO",
    fecha: 2018,
    tipo: "Modelo",
    materia: "CIVIL",
    pdf: bookList["Ebook14"],
  },
  {
    id: 15,
    nombre: "PRESCRIPCIÓN EXTRAORDINARIA ADQUISITIVA DE DOMINIO -  ORDINARIO",
    fecha: 2018,
    tipo: "Modelo",
    materia: "CIVIL",
    pdf: bookList["Ebook15"],
  },
  {
    id: 16,
    nombre: "PRESCRIPCIÓN ORDINARIA DE DOMINIO - ORDINARIO",
    fecha: 2018,
    tipo: "Modelo",
    materia: "CIVIL",
    pdf: bookList["Ebook16"],
  },
  {
    id: 17,
    nombre: "PRESUNCIÓN DE MUERTE - ORDINARIO",
    fecha: 2018,
    tipo: "Modelo",
    materia: "CIVIL",
    pdf: bookList["Ebook17"],
  },
  {
    id: 18,
    nombre: "REFORMA DE TESTAMENTO - ORDINARIO",
    fecha: 2018,
    tipo: "Modelo",
    materia: "CIVIL",
    pdf: bookList["Ebook18"],
  },
  {
    id: 19,
    nombre: "REIVINDICACIÓN - ORDINARIO",
    fecha: 2018,
    tipo: "Modelo",
    materia: "CIVIL",
    pdf: bookList["Ebook19"],
  },
  {
    id: 20,
    nombre:
      "RESCISIÓN DE CONTRATO POR LESIÓN ENORME POR PAGO MAYOR AL JUSTO PRECIO - ORDINARIO",
    fecha: 2018,
    tipo: "Modelo",
    materia: "CIVIL",
    pdf: bookList["Ebook20"],
  },
  {
    id: 21,
    nombre:
      "RESCISIÓN DE CONTRATO POR LESIÓN ENORME POR PAGO MENOR AL JUSTO PRECIO - ORDINARIO",
    fecha: 2018,
    tipo: "Modelo",
    materia: "CIVIL",
    pdf: bookList["Ebook21"],
  },
  {
    id: 22,
    nombre: "RESCISIÓN DE CONTRATO POR VICIOS REDHIBITORIOS - ORDINARIO",
    fecha: 2018,
    tipo: "Modelo",
    materia: "CIVIL",
    pdf: bookList["Ebook22"],
  },
  {
    id: 23,
    nombre: "RESOLUCIÓN DE CONTRATO DE PROMESA DE COMPRAVENTA -  ORDINARIO",
    fecha: 2018,
    tipo: "Modelo",
    materia: "CIVIL",
    pdf: bookList["Ebook23"],
  },
  {
    id: 24,
    nombre:
      "TERMINACIÓN DE LA UNIÓN DE HECHO CONTROVERTIDA CON HIJOS DEPENDIENTES -  VOLUNTARIO",
    fecha: 2018,
    tipo: "Modelo",
    materia: "CIVIL",
    pdf: bookList["Ebook24"],
  },
  {
    id: 25,
    nombre:
      "TERMINACIÓN DE LA UNIÓN DE HECHO CONTROVERTIDA Y LIQUIDACIÓN DE LA SOCIEDAD DE BIENES CON HIJOS DEPENDIENTES - VOLUNTARIO",
    fecha: 2018,
    tipo: "Modelo",
    materia: "CIVIL",
    pdf: bookList["Ebook25"],
  },
  {
    id: 26,
    nombre: "ACCIÓN ORDINARIA DE PROTECCIÓN",
    fecha: 2018,
    tipo: "Modelo",
    materia: "CONSTITUCIONAL",
    pdf: bookList["Ebook26"],
  },
  {
    id: 27,
    nombre: "HABEAS CORPUS",
    fecha: 2018,
    tipo: "Modelo",
    materia: "CONSTITUCIONAL",
    pdf: bookList["Ebook27"],
  },
  {
    id: 28,
    nombre: "HABEAS DATA",
    fecha: 2018,
    tipo: "Modelo",
    materia: "CONSTITUCIONAL",
    pdf: bookList["Ebook28"],
  },
  {
    id: 29,
    nombre: "CONTRATO DE ARRENDAMIENTO MEDIANTE ESCRITURA PÚBLICA",
    fecha: 2018,
    tipo: "Modelo",
    materia: "CONTRATOS",
    pdf: bookList["Ebook29"],
  },
  {
    id: 30,
    nombre: "CONTRATO DE ARRENDAMIENTO",
    fecha: 2018,
    tipo: "Modelo",
    materia: "CONTRATOS",
    pdf: bookList["Ebook30"],
  },
  {
    id: 31,
    nombre: "CONTRATO DE COMPRAVENTA DE VEHICULO",
    fecha: 2018,
    tipo: "Modelo",
    materia: "CONTRATOS",
    pdf: bookList["Ebook31"],
  },
  {
    id: 32,
    nombre:
      "CONTRATO DE TRABAJO  CON JORNADA PARCIAL PERMANENTE CON PERÍODO DE PRUEBA",
    fecha: 2018,
    tipo: "Modelo",
    materia: "CONTRATOS",
    pdf: bookList["Ebook32"],
  },
  {
    id: 33,
    nombre:
      "CONTRATO DE TRABAJO CON JORNADA PARCIAL PERMANENTE PARA SERVICIO DOMESTICO CON PERÍODO DE PRUEBA",
    fecha: 2018,
    tipo: "Modelo",
    materia: "CONTRATOS",
    pdf: bookList["Ebook33"],
  },
  {
    id: 34,
    nombre:
      "CONTRATO DE TRABAJO DE SERVICIO DOMÉSICO POR TIEMPO INDEFINIDO CON PERÍODO DE PRUEBA",
    fecha: 2018,
    tipo: "Modelo",
    materia: "CONTRATOS",
    pdf: bookList["Ebook34"],
  },
  {
    id: 35,
    nombre: "CONTRATO DE TRABAJO POR TIEMPO INDEFINIDO CON PERÍODO DE PRUEBA",
    fecha: 2018,
    tipo: "Modelo",
    materia: "CONTRATOS",
    pdf: bookList["Ebook35"],
  },
  {
    id: 36,
    nombre: "ALIMENTOS PARA MUJER EMBARAZADA - SUMARIO",
    fecha: 2018,
    tipo: "Modelo",
    materia: "FAMILIA",
    pdf: bookList["Ebook36"],
  },
  {
    id: 37,
    nombre: "AUTORIZACIÓN DE SALIDA DE MENOR - NOTARIAL",
    fecha: 2018,
    tipo: "Modelo",
    materia: "FAMILIA",
    pdf: bookList["Ebook37"],
  },
  {
    id: 38,
    nombre: "AUTORIZACIÓN VENTA IMNUEBLE DE MENOR - VOLUNTARIO",
    fecha: 2018,
    tipo: "Modelo",
    materia: "FAMILIA",
    pdf: bookList["Ebook38"],
  },
  {
    id: 39,
    nombre:
      "DILIGENCIA PREPARATORIA, PARA EN UN FUTURO PROCESO PEDIR LA PÉRDIDA JUDICIAL DE PATRIA POTESTAD",
    fecha: 2018,
    tipo: "Modelo",
    materia: "FAMILIA",
    pdf: bookList["Ebook39"],
  },
  {
    id: 40,
    nombre: "DISOLUCIÓN UNILATERAL  DE LA SOCIEDAD CONYUGAL - VOLUNTARIO",
    fecha: 2018,
    tipo: "Modelo",
    materia: "FAMILIA",
    pdf: bookList["Ebook40"],
  },
  {
    id: 41,
    nombre: "DIVORCIO CONTENCIOSO POR ABANDONO - SUMARIO",
    fecha: 2018,
    tipo: "Modelo",
    materia: "FAMILIA",
    pdf: bookList["Ebook41"],
  },
  {
    id: 42,
    nombre:
      "DIVORCIO CONTENCIOSO POR ABANDONO CON LIQUIDACIÓN DE LA SOCIEDAD CONYUGAL - SUMARIO",
    fecha: 2018,
    tipo: "Modelo",
    materia: "FAMILIA",
    pdf: bookList["Ebook42"],
  },
  {
    id: 43,
    nombre:
      "DIVORCIO POR MUTUO CONSENTIMIENTO CON HIJOS DEPENDIENTES -VOLUNTARIO",
    fecha: 2018,
    tipo: "Modelo",
    materia: "FAMILIA",
    pdf: bookList["Ebook43"],
  },
  {
    id: 44,
    nombre:
      "DIVORCIO POR MUTUO CONSENTIMIENTO Y LIQUIDACIÓN DE LA SOCIEDAD CONYUGAL  CON HIJOS DEPENDIENTES -  VOLUNTARIO",
    fecha: 2018,
    tipo: "Modelo",
    materia: "FAMILIA",
    pdf: bookList["Ebook44"],
  },
  {
    id: 45,
    nombre:
      "EJECUCIÓN DE ACTA DE MEDIACIÓN POR PENSIONES ALIMENTICIAS CON OBLIGACIÓN DE DAR DINERO O BIENES DE GÉNERO -  PROCEDIMIENTO DE EJECUCIÓN",
    fecha: 2018,
    tipo: "Modelo",
    materia: "FAMILIA",
    pdf: bookList["Ebook45"],
  },
  {
    id: 46,
    nombre: "IMPUGNACIÓN DE PATERNIDAD – SUMARIO",
    fecha: 2018,
    tipo: "Modelo",
    materia: "FAMILIA",
    pdf: bookList["Ebook46"],
  },
  {
    id: 47,
    nombre: "INSCRIPCIÓN TARDÍA DE NACIMINETO",
    fecha: 2018,
    tipo: "Modelo",
    materia: "FAMILIA",
    pdf: bookList["Ebook47"],
  },
  {
    id: 48,
    nombre: "NOMBRAMIENTO DE CURADOR PARA SEGUNDAS NUPCIAS – VOLUNTARIO",
    fecha: 2018,
    tipo: "Modelo",
    materia: "FAMILIA",
    pdf: bookList["Ebook48"],
  },
  {
    id: 49,
    nombre: "NULIDAD DE MATRIMONIO CIVIL – ORDINARIO",
    fecha: 2018,
    tipo: "Modelo",
    materia: "FAMILIA",
    pdf: bookList["Ebook49"],
  },
  {
    id: 50,
    nombre: "PÉRDIDA JUDICIAL DE PATRIA POTESTAD",
    fecha: 2018,
    tipo: "Modelo",
    materia: "FAMILIA",
    pdf: bookList["Ebook50"],
  },
  {
    id: 51,
    nombre:
      "SOLICITUD DE PROTOCOLIZACIÓN DE ACTA DE DISCERNINIENTO Y POSESIÓN DE CURADOR ESPECIAL PARA SEGUNDAS NUPCIAS - NOTARIAL",
    fecha: 2018,
    tipo: "Modelo",
    materia: "FAMILIA",
    pdf: bookList["Ebook51"],
  },
  {
    id: 52,
    nombre:
      "TERMINACIÓN DE LA UNIÓN DE HECHO POR MUTUO CONSENTIMIENTO CON HIJOS DEPENDIENTES - VOLUNTARIO",
    fecha: 2018,
    tipo: "Modelo",
    materia: "FAMILIA",
    pdf: bookList["Ebook52"],
  },
  {
    id: 53,
    nombre:
      "TERMINACIÓN DE LA UNIÓN DE HECHO POR MUTUO CONSENTIMIENTO CON HIJOS DEPENDIENTES CON LIQUIDACIÓN DE BIENES - VOLUNTARIO",
    fecha: 2018,
    tipo: "Modelo",
    materia: "FAMILIA",
    pdf: bookList["Ebook53"],
  },
  {
    id: 54,
    nombre: "CONTRATO DE ARRENDAMIENTO MEDIANTE ESCRITURA PÚBLICA",
    fecha: 2018,
    tipo: "Modelo",
    materia: "INQUILINATO",
    pdf: bookList["Ebook54"],
  },
  {
    id: 55,
    nombre: "CONTRATO DE ARRENDAMIENTO",
    fecha: 2018,
    tipo: "Modelo",
    materia: "INQUILINATO",
    pdf: bookList["Ebook55"],
  },
  {
    id: 56,
    nombre: "NOTIFICACIÓN DE SOLEMNIZACIÓN DE  DESAHUCIO DE ARRENDAMIENTO",
    fecha: 2018,
    tipo: "Modelo",
    materia: "INQUILINATO",
    pdf: bookList["Ebook56"],
  },
  {
    id: 57,
    nombre: "TERMINACIÓN DE CONTRATO DE ARRENDAMIENTO POR CAUSAL",
    fecha: 2018,
    tipo: "Modelo",
    materia: "INQUILINATO",
    pdf: bookList["Ebook57"],
  },
  {
    id: 58,
    nombre: "TERMINACIÓN DE CONTRATO DE ARRENDAMIENTO POR MORA",
    fecha: "",
    tipo: "Modelo",
    materia: "INQUILINATO",
    pdf: bookList["Ebook58"],
  },
  {
    id: 59,
    nombre: "ACUERDO DE JUBILACIÓN PATRONAL MEDIANTE FONDO GLOBAL  - NOTARIAL",
    fecha: "",
    tipo: "Modelo",
    materia: "LABORAL",
    pdf: bookList["Ebook59"],
  },
  {
    id: 60,
    nombre: "COBRO DE REMUNERACIONES - MONITORIO",
    fecha: 2018,
    tipo: "Modelo",
    materia: "LABORAL",
    pdf: bookList["Ebook60"],
  },
  {
    id: 61,
    nombre:
      "CONTRATO DE TRABAJO  CON JORNADA PARCIAL PERMANENTE CON PERÍODO DE PRUEBA",
    fecha: 2018,
    tipo: "Modelo",
    materia: "LABORAL",
    pdf: bookList["Ebook61"],
  },
  {
    id: 62,
    nombre:
      "CONTRATO DE TRABAJO CON JORNADA PARCIAL PERMANENTE PARA SERVICIO DOMESTICO CON PERÍODO DE PRUEBA",
    fecha: 2018,
    tipo: "Modelo",
    materia: "LABORAL",
    pdf: bookList["Ebook62"],
  },
  {
    id: 63,
    nombre:
      "CONTRATO DE TRABAJO DE SERVICIO DOMÉSICO POR TIEMPO INDEFINIDO CON PERÍODO DE PRUEBA",
    fecha: 2018,
    tipo: "Modelo",
    materia: "LABORAL",
    pdf: bookList["Ebook63"],
  },
  {
    id: 64,
    nombre: "CONTRATO DE TRABAJO POR TIEMPO INDEFINIDO CON PERÍODO DE PRUEBA",
    fecha: 2018,
    tipo: "Modelo",
    materia: "LABORAL",
    pdf: bookList["Ebook64"],
  },
  {
    id: 65,
    nombre:
      "DENUNCIA INSPECTORIA DEL TRABAJO PARA PAGO DE LIQUIDACIÓN Y FINIQUITO",
    fecha: 2018,
    tipo: "Modelo",
    materia: "LABORAL",
    pdf: bookList["Ebook65"],
  },
  {
    id: 66,
    nombre:
      "DENUNCIA INSPECTORIA DEL TRABAJO POR ACCIDENTE DE TRABAJO O ENFERMEDAD PROFESIONAL",
    fecha: 2018,
    tipo: "Modelo",
    materia: "LABORAL",
    pdf: bookList["Ebook66"],
  },
  {
    id: 67,
    nombre: "DESPIDO INTEMPESTIVO",
    fecha: 2018,
    tipo: "Modelo",
    materia: "LABORAL",
    pdf: bookList["Ebook67"],
  },
  {
    id: 68,
    nombre: "NOTIFICACIÓN DE DESAHUCIO LABORAL",
    fecha: 2018,
    tipo: "Modelo",
    materia: "LABORAL",
    pdf: bookList["Ebook68"],
  },
  {
    id: 69,
    nombre:
      "SOLICITUD DE VISTO BUENO ANTE INSPECTORÍA DEL TRABAJO CON SUSPENSIÓN DE LABORES",
    fecha: 2018,
    tipo: "Modelo",
    materia: "LABORAL",
    pdf: bookList["Ebook69"],
  },
  {
    id: 70,
    nombre: "SOLICITUD DE VISTO BUENO SIN SUSPENCIÓN DE LABORES",
    fecha: 2018,
    tipo: "Modelo",
    materia: "LABORAL",
    pdf: bookList["Ebook70"],
  },
  {
    id: 71,
    nombre: "DILIGENCIA PREPARATORIA DE EXHIBICIÓN",
    fecha: 2018,
    tipo: "Modelo",
    materia: "MULTICOMPETENTE",
    pdf: bookList["Ebook71"],
  },
  {
    id: 72,
    nombre: "DILIGENCIA PREPARATORIA DE RECONOCIMIENTO DE DOCUMENTO PRIVADO",
    fecha: 2018,
    tipo: "Modelo",
    materia: "MULTICOMPETENTE",
    pdf: bookList["Ebook72"],
  },
  {
    id: 73,
    nombre: "ACLARATORIA - MINUTA",
    fecha: 2018,
    tipo: "Modelo",
    materia: "NOTARIAL",
    pdf: bookList["Ebook73"],
  },
  {
    id: 74,
    nombre: "ACLARATORIA Y RECTIFICATORIA - MINUTA",
    fecha: 2018,
    tipo: "Modelo",
    materia: "NOTARIAL",
    pdf: bookList["Ebook74"],
  },
  {
    id: 75,
    nombre: "ACUERDO DE JUBILACIÓN PATRONAL MEDIANTE FONDO GLOBAL  - LABORAL",
    fecha: 2018,
    tipo: "Modelo",
    materia: "NOTARIAL",
    pdf: bookList["Ebook75"],
  },
  {
    id: 76,
    nombre:
      "AUMENTO DE CAPITAL  Y REFORMA DE ESTATUTOS  DE UNA COMPAÑÍA-  SOCIETARIO",
    fecha: 2018,
    tipo: "Modelo",
    materia: "NOTARIAL",
    pdf: bookList["Ebook76"],
  },
  {
    id: 77,
    nombre: "AUTORIZACIÓN DE ADOSAMIENTO - MINUTA",
    fecha: 2018,
    tipo: "Modelo",
    materia: "NOTARIAL",
    pdf: bookList["Ebook77"],
  },
  {
    id: 78,
    nombre: "AUTORIZACIÓN DE SALIDA DE MENOR - FAMILIA",
    fecha: 2018,
    tipo: "Modelo",
    materia: "NOTARIAL",
    pdf: bookList["Ebook78"],
  },
  {
    id: 79,
    nombre: "AUTORIZACIÓN MUTUA - MINUTA",
    fecha: 2018,
    tipo: "Modelo",
    materia: "NOTARIAL",
    pdf: bookList["Ebook79"],
  },
  {
    id: 80,
    nombre:
      "CAMBIO DE DOMICILIO  Y REFORMA DE ESTATUTOS  DE UNA COMPAÑÍA- SOCIETARIO",
    fecha: 2018,
    tipo: "Modelo",
    materia: "NOTARIAL",
    pdf: bookList["Ebook80"],
  },
  {
    id: 81,
    nombre:
      "CAMBIO DE OBJETO SOCIAL  Y REFORMA DE ESTATUTOS  DE UNA COMPAÑÍA- SOCIETARIO",
    fecha: 2018,
    tipo: "Modelo",
    materia: "NOTARIAL",
    pdf: bookList["Ebook81"],
  },
  {
    id: 82,
    nombre:
      "CAMBIO DE RAZÓN SOCIAL  Y REFORMA DE ESTATUTOS  DE UNA COMPAÑÍA- SOCIETARIO",
    fecha: 2018,
    tipo: "Modelo",
    materia: "NOTARIAL",
    pdf: bookList["Ebook82"],
  },
  {
    id: 83,
    nombre: "CANCELACIÓN DE HIPOTECA  ABIERTA",
    fecha: 2018,
    tipo: "Modelo",
    materia: "NOTARIAL",
    pdf: bookList["Ebook83"],
  },
  {
    id: 84,
    nombre: "CAPITULACIONES MATRIMONIALES ENTRE CÓNYUGES",
    fecha: 2018,
    tipo: "Modelo",
    materia: "NOTARIAL",
    pdf: bookList["Ebook84"],
  },
  {
    id: 85,
    nombre: "CAPITULACIONES MATRIMONIALES PARA FUTURAS NUPCIAS",
    fecha: 2018,
    tipo: "Modelo",
    materia: "NOTARIAL",
    pdf: bookList["Ebook85"],
  },
  {
    id: 86,
    nombre: "CERTIFICACIÓN DE DOCUMENTO DESCARGADO DESDE LA WEB",
    fecha: 2018,
    tipo: "Modelo",
    materia: "NOTARIAL",
    pdf: bookList["Ebook86"],
  },
  {
    id: 87,
    nombre: "CESIÓN DE DERECHOS  Y MEJORAS",
    fecha: 2018,
    tipo: "Modelo",
    materia: "NOTARIAL",
    pdf: bookList["Ebook87"],
  },
  {
    id: 88,
    nombre: "CESIÓN DE PARTICIPACIONES EN COMPAÑÍA LIMITADA - SOCIETARIO",
    fecha: 2018,
    tipo: "Modelo",
    materia: "NOTARIAL",
    pdf: bookList["Ebook88"],
  },
  {
    id: 89,
    nombre: "COMPRAVENTA DE INMUEBLE BAJO RÉGIMEN DE PROPIEDAD HORIZONTAL",
    fecha: 2018,
    tipo: "Modelo",
    materia: "NOTARIAL",
    pdf: bookList["Ebook89"],
  },
  {
    id: 90,
    nombre: "COMPRAVENTA DE INMUEBLE",
    fecha: 2018,
    tipo: "Modelo",
    materia: "NOTARIAL",
    pdf: bookList["Ebook90"],
  },
  {
    id: 91,
    nombre:
      "CONTRATO DE ARRENDAMIENTO MEDIANTE ESCRITURA PÚBLICA - INQUILINATO",
    fecha: 2018,
    tipo: "Modelo",
    materia: "NOTARIAL",
    pdf: bookList["Ebook91"],
  },
  {
    id: 92,
    nombre: "DACIÓN EN PAGO",
    fecha: 2018,
    tipo: "Modelo",
    materia: "NOTARIAL",
    pdf: bookList["Ebook92"],
  },
  {
    id: 93,
    nombre: "DECLARACIÓN JURAMENTADA",
    fecha: 2018,
    tipo: "Modelo",
    materia: "NOTARIAL",
    pdf: bookList["Ebook93"],
  },
  {
    id: 94,
    nombre: "DONACIÓN CON  RESERVA DE USUFRUCTO",
    fecha: 2018,
    tipo: "Modelo",
    materia: "NOTARIAL",
    pdf: bookList["Ebook94"],
  },
  {
    id: 95,
    nombre: "DONACIÓN SIN RESERVA DE USUFRUCTO",
    fecha: 2018,
    tipo: "Modelo",
    materia: "NOTARIAL",
    pdf: bookList["Ebook95"],
  },
  {
    id: 96,
    nombre: "EXTINCIÓN DE USUFRUCTO USO Y HABITACIÓN",
    fecha: 2018,
    tipo: "Modelo",
    materia: "NOTARIAL",
    pdf: bookList["Ebook96"],
  },
  {
    id: 97,
    nombre: "INFORMACION SUMARIA",
    fecha: 2018,
    tipo: "Modelo",
    materia: "NOTARIAL",
    pdf: bookList["Ebook97"],
  },
  {
    id: 98,
    nombre: "INSINUACIÓN JUDICIAL PARA DONACIÓN CON RESERVA DE USUFRUCTO",
    fecha: 2018,
    tipo: "Modelo",
    materia: "NOTARIAL",
    pdf: bookList["Ebook98"],
  },
  {
    id: 99,
    nombre: "INSINUACIÓN JUDICIAL PARA DONACIÓN SIN RESERVA DE USUFRUCTO",
    fecha: 2018,
    tipo: "Modelo",
    materia: "NOTARIAL",
    pdf: bookList["Ebook99"],
  },
  {
    id: 100,
    nombre: "NOMBRAMIENTO DE PROCURADOR COMÚN",
    fecha: 2018,
    tipo: "Modelo",
    materia: "NOTARIAL",
    pdf: bookList["Ebook100"],
  },
  {
    id: 101,
    nombre: "NOTIFICACIÓN DE SOLEMNIZACIÓN DE  DESAHUCIO DE ARRENDAMIENTO",
    fecha: 2018,
    tipo: "Modelo",
    materia: "NOTARIAL",
    pdf: bookList["Ebook101"],
  },
  {
    id: 102,
    nombre: "PODER ESPECIAL",
    fecha: 2018,
    tipo: "Modelo",
    materia: "NOTARIAL",
    pdf: bookList["Ebook102"],
  },
  {
    id: 103,
    nombre:
      "POSESIÓN EFECTIVA CON CÓNYUGE SOBREVIVIENTE Y COMPARECENCIA DE TODOS LOS HEREDEROS CON ENUMERACIÓN DE BIENES",
    fecha: 2018,
    tipo: "Modelo",
    materia: "NOTARIAL",
    pdf: bookList["Ebook103"],
  },
  {
    id: 104,
    nombre:
      "POSESIÓN EFECTIVA CON CÓNYUGE SOBREVIVIENTE Y COMPARECENCIA DE TODOS LOS HEREDEROS SIN ENUMERACIÓN DE BIENES",
    fecha: 2018,
    tipo: "Modelo",
    materia: "NOTARIAL",
    pdf: bookList["Ebook104"],
  },
  {
    id: 105,
    nombre: "PROCURACIÓN JUDICIAL",
    fecha: 2018,
    tipo: "Modelo",
    materia: "NOTARIAL",
    pdf: bookList["Ebook105"],
  },
  {
    id: 106,
    nombre: "PROMESA DE COMPRAVENTA",
    fecha: 2018,
    tipo: "Modelo",
    materia: "NOTARIAL",
    pdf: bookList["Ebook106"],
  },
  {
    id: 107,
    nombre:
      "PRORROGA DE VIGENCIA DEL CONTRATO SOCIAL Y REFORMA DE ESTATUTOS DE UNA COMPAÑÍA",
    fecha: 2018,
    tipo: "Modelo",
    materia: "NOTARIAL",
    pdf: bookList["Ebook107"],
  },
  {
    id: 108,
    nombre: "REACTIVACIÓN Y REFORMA DE ESTATUTOS DE UNA COMPAÑÍA",
    fecha: 2018,
    tipo: "Modelo",
    materia: "NOTARIAL",
    pdf: bookList["Ebook108"],
  },
  {
    id: 109,
    nombre: "RESCILIACIÓN",
    fecha: 2018,
    tipo: "Modelo",
    materia: "NOTARIAL",
    pdf: bookList["Ebook109"],
  },
  {
    id: 110,
    nombre: "REVOCATORIA EN CONSENSO DE PODER",
    fecha: 2018,
    tipo: "Modelo",
    materia: "NOTARIAL",
    pdf: bookList["Ebook110"],
  },
  {
    id: 111,
    nombre: "REVOCATORIA UNILATERAL DE PODER",
    fecha: 2018,
    tipo: "Modelo",
    materia: "NOTARIAL",
    pdf: bookList["Ebook111"],
  },
  {
    id: 112,
    nombre: "SOLEMNIZAR UNIÓN DE HECHO",
    fecha: 2018,
    tipo: "Modelo",
    materia: "NOTARIAL",
    pdf: bookList["Ebook112"],
  },
  {
    id: 113,
    nombre: "SOLICITUD DE DILIGENCIA DE AMOJONAMIENTO Y DESLINDE RURAL",
    fecha: 2018,
    tipo: "Modelo",
    materia: "NOTARIAL",
    pdf: bookList["Ebook113"],
  },
  {
    id: 114,
    nombre:
      "SOLICITUD DE PROTOCOLIZACIÓN DE ACTA DE DISCERNINIENTO Y POSESIÓN DE CURADOR ESPECIAL PARA SEGUNDAS NUPCIAS",
    fecha: 2018,
    tipo: "Modelo",
    materia: "NOTARIAL",
    pdf: bookList["Ebook114"],
  },
  {
    id: 115,
    nombre: "SUBROGACIÓN PATRIMONIO FAMILIAR",
    fecha: 2018,
    tipo: "Modelo",
    materia: "NOTARIAL",
    pdf: bookList["Ebook115"],
  },
  {
    id: 116,
    nombre: "ACUSACION PARTICULAR ACCIDENTE DE TRÁNSITO",
    fecha: 2018,
    tipo: "Modelo",
    materia: "PENAL",
    pdf: bookList["Ebook116"],
  },
  {
    id: 117,
    nombre: "ACUSACION PARTICULAR EN DELITO DE ACCIÓN PÚBLICA",
    fecha: 2018,
    tipo: "Modelo",
    materia: "PENAL",
    pdf: bookList["Ebook117"],
  },
  {
    id: 118,
    nombre: "DENUNCIA DEFENSA DEL CONSUMIDOR - CONTRAVENCIÓN",
    fecha: 2018,
    tipo: "Modelo",
    materia: "PENAL",
    pdf: bookList["Ebook118"],
  },
  {
    id: 119,
    nombre: "DENUNCIA ROBO",
    fecha: 2018,
    tipo: "Modelo",
    materia: "PENAL",
    pdf: bookList["Ebook119"],
  },
  {
    id: 120,
    nombre: "IMPUGNACIÓN NOTIFICACIÓN TRÁNSITO",
    fecha: 2018,
    tipo: "Modelo",
    materia: "PENAL",
    pdf: bookList["Ebook120"],
  },
  {
    id: 121,
    nombre:
      "QUERELLA POR CALUMNIA ATREVES DE REDES SOCIALES, FACEBOOK, TWITTER, WHATSAPP, CORREO ELECTRÓNICO, ETC.",
    fecha: 2018,
    tipo: "Modelo",
    materia: "PENAL",
    pdf: bookList["Ebook121"],
  },
  {
    id: 122,
    nombre: "QUERELLA POR LESIONES",
    fecha: 2018,
    tipo: "Modelo",
    materia: "PENAL",
    pdf: bookList["Ebook122"],
  },
  {
    id: 123,
    nombre:
      "AUMENTO DE CAPITAL  Y REFORMA DE ESTATUTOS  DE UNA COMPAÑÍA-  NOTARIAL",
    fecha: 2018,
    tipo: "Modelo",
    materia: "SOCIETARIO",
    pdf: bookList["Ebook123"],
  },
  {
    id: 124,
    nombre:
      "CAMBIO DE DOMICILIO  Y REFORMA DE ESTATUTOS  DE UNA COMPAÑÍA-  NOTARIAL",
    fecha: 2018,
    tipo: "Modelo",
    materia: "SOCIETARIO",
    pdf: bookList["Ebook124"],
  },
  {
    id: 125,
    nombre:
      "CAMBIO DE OBJETO SOCIAL  Y REFORMA DE ESTATUTOS  DE UNA COMPAÑÍA-  NOTARIAL",
    fecha: 2018,
    tipo: "Modelo",
    materia: "SOCIETARIO",
    pdf: bookList["Ebook125"],
  },
  {
    id: 126,
    nombre:
      "CAMBIO DE RAZÓN SOCIAL  Y REFORMA DE ESTATUTOS  DE UNA COMPAÑÍA-  NOTARIAL",
    fecha: 2018,
    tipo: "Modelo",
    materia: "SOCIETARIO",
    pdf: bookList["Ebook126"],
  },
  {
    id: 127,
    nombre: "CESIÓN DE PARTICIPACIONES EN COMPAÑÍA LIMITADA - NOTARIAL",
    fecha: 2018,
    tipo: "Modelo",
    materia: "SOCIETARIO",
    pdf: bookList["Ebook127"],
  },
  {
    id: 128,
    nombre:
      "PRORROGA DE VIGENCIA DEL CONTRATO SOCIAL Y REFORMA DE ESTATUTOS DE UNA COMPAÑÍA – NOTARIAL",
    fecha: 2018,
    tipo: "Modelo",
    materia: "SOCIETARIO",
    pdf: bookList["Ebook128"],
  },
  {
    id: 129,
    nombre: "REACTIVACIÓN Y REFORMA DE ESTATUTOS DE UNA COMPAÑÍA – NOTARIAL",
    fecha: 2018,
    tipo: "Modelo",
    materia: "SOCIETARIO",
    pdf: bookList["Ebook129"],
  },
];
var libros = books;
export default libros;
