//Import React Library
import React, { Component } from "react";
//Import styles
import "./css/App.css";

//Import components
import Hjh from "./Components/Layouts/Hjh";

import { ProductProvider } from "./Context/context"; //Import context api

export default class App extends Component {
  render() {
    return (
      <ProductProvider>
                
          <Hjh />
      
      </ProductProvider>
    );
  }
}
