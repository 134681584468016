//Import react library
import React, { Component } from "react";
//Import Collapse reusable component
import Collapse from "./Collapse";
//Import material design components
import { MDBCol, MDBRow, MDBTooltip } from "mdbreact";
// Import ProductConsumer from Context Api (used for state management)
import { ProductConsumer } from "../../Context/context";
//Import Title component
import SectionTitle from "../Reusable/SectionTitle";

import modelosIcon from "../../assets/buttons/modelos.png";
import vademecumIcon from "../../assets/buttons/vademecum.png";
import hjhIcon from "../../assets/title/hjh-title.png"
export default class Hjh extends Component {
  render() {
    return (
      <ProductConsumer>
        {(
          value //value contains the data defined in the product provider in context.js
        ) => (
          <React.Fragment>
            <SectionTitle src={hjhIcon} />
            {/*Title */}
            <MDBRow className="noSpace mt-n5">
              <MDBCol size="6" sm="5" className="text-center text-sm-right">
                {/*Container for vademecum section */}
                <MDBTooltip
                  domElement
                  tag="span"
                  placement="top"
                  className="tooltipToHide"
                >
                  <img
                    className="img-fluid w-75 folderBtn"
                    src={vademecumIcon}
                    onClick={() => value.toggleCollapse("1")}
                    alt="vademecum logo"
                  />
                  <span>Flujogramas Procesales más utilizados</span>
                </MDBTooltip>
              </MDBCol>
              <MDBCol size="2" className="d-none d-sm-block" />
              {/*Contianer for model section */}
              <MDBCol size="6" sm="5" className="text-center text-sm-left">
                <MDBTooltip
                  domElement
                  tag="span"
                  placement="top"
                  className="tooltipToHide"
                >
                  <img
                    className="img-fluid w-75 folderBtn"
                    src={modelosIcon}
                    onClick={() => value.toggleCollapse("2")}
                    alt="modelos logo"
                  />
                  <span>Modelos procesales, 7 materias, 80 textos en PDF</span>
                </MDBTooltip>
              </MDBCol>
            </MDBRow>
            <Collapse id="1" />
            {/*Vademecum Info */}
            <Collapse id="2" />
            {/*Models Info */}
          </React.Fragment>
        )}
      </ProductConsumer>
    );
  }
}
