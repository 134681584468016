export const Ebook1 = require("./Código Integral Penal/ACTUACIONES DE INVESTIGACIÓN.pdf");
export const Ebook2 = require("./Código Integral Penal/ACTUACIONES ESPECIALES DE INVESTIGACIÓN .pdf");
export const Ebook3 = require("./Código Integral Penal/ACUSACIÓN PARTICULAR.pdf");
export const Ebook4 = require("./Código Integral Penal/ALLANAMIENTO - PROCEDIMIENTO.pdf");
export const Ebook5 = require("./Código Integral Penal/ALLANAMIENTO .pdf");
export const Ebook6 = require("./Código Integral Penal/CAUCIÓN.pdf");
export const Ebook7 = require("./Código Integral Penal/COMPETENCIA.pdf");
export const Ebook8 = require("./Código Integral Penal/DENUNCIA.pdf");
export const Ebook9 = require("./Código Integral Penal/EJERCICIO DE LA ACCIÓN PENAL.pdf");
export const Ebook10 = require("./Código Integral Penal/EL TESTIMONIO.pdf");
export const Ebook11 = require("./Código Integral Penal/EXTINCIÓN DE LA ACCIÓN PENAL.pdf");
export const Ebook12 = require("./Código Integral Penal/JURISDICCIÓN.pdf");
export const Ebook13 = require("./Código Integral Penal/LA PERICIA.pdf");
export const Ebook14 = require("./Código Integral Penal/LA PRUEBA.pdf");
export const Ebook15 = require("./Código Integral Penal/LA VÍCTIMA.pdf");
export const Ebook16 = require("./Código Integral Penal/LAS AUDIENCIAS.pdf");
export const Ebook17 = require("./Código Integral Penal/MEDIDAS CAUTELARES SOBRE BIENES.pdf");
export const Ebook18 = require("./Código Integral Penal/MEDIDAS DE PROTECCIÓN.pdf");
export const Ebook19 = require("./Código Integral Penal/MEDIOS DE COMUNICACIÓN.pdf");
export const Ebook20 = require("./Código Integral Penal/ORALIDAD.pdf");
export const Ebook21 = require("./Código Integral Penal/PRESCRIPCIÓN DEL EJERCICIO DE LA ACCIÓN PENAL.pdf");
export const Ebook22 = require("./Código Integral Penal/PRISIÓN PREVENTIVA.pdf");
export const Ebook23 = require("./Código Integral Penal/PRUEBA DOCUMENTAL.pdf");
export const Ebook24 = require("./Código Integral Penal/RECONOCIMIENTO DEL LUGAR DE LOS HECHOS.pdf");
export const Ebook25 = require("./Código Integral Penal/REGISTRO DE VEHÍCULOS .pdf");
export const Ebook26 = require("./Código Integral Penal/REGISTROS.pdf");
export const Ebook27 = require("./Código Integral Penal/SUJETOS PROCESALES.pdf");
export const Ebook28 = require("./Código Integral Penal/TESTIMONIO DE LA VÍCTIMA.pdf");
export const Ebook29 = require("./Código Integral Penal/TESTIMONIO DE TERCEROS.pdf");
export const Ebook30 = require("./Código Orgánico General de Procesos (COGEP)/ACCIONES EN PROCEDIMIENTO CONTENCIOSO ADMINISTRATIVO.pdf");
export const Ebook31 = require("./Código Orgánico General de Procesos (COGEP)/ACUMULACIÓN DE PROCESOS.pdf");
export const Ebook32 = require("./Código Orgánico General de Procesos (COGEP)/APREMIOS.pdf");
export const Ebook33 = require("./Código Orgánico General de Procesos (COGEP)/AUDIENCIA DE EJECUCIÓN.pdf");
export const Ebook34 = require("./Código Orgánico General de Procesos (COGEP)/AUDIENCIA.pdf");
export const Ebook35 = require("./Código Orgánico General de Procesos (COGEP)/CALIFICACIÓN DE LA DEMANDA.pdf");
export const Ebook36 = require("./Código Orgánico General de Procesos (COGEP)/CITACIÓN.pdf");
export const Ebook37 = require("./Código Orgánico General de Procesos (COGEP)/COMPETENCIA.pdf");
export const Ebook38 = require("./Código Orgánico General de Procesos (COGEP)/CONTENIDO DE LA DEMANDA.pdf");
export const Ebook39 = require("./Código Orgánico General de Procesos (COGEP)/CONTENIDO DEL INFORME PERICIAL.pdf");
export const Ebook40 = require("./Código Orgánico General de Procesos (COGEP)/CONTESTACIÓN A LA DEMANDA.pdf");
export const Ebook41 = require("./Código Orgánico General de Procesos (COGEP)/COSTAS.pdf");
export const Ebook42 = require("./Código Orgánico General de Procesos (COGEP)/DECLARACIÓN DE PARTE.pdf");
export const Ebook43 = require("./Código Orgánico General de Procesos (COGEP)/DECLARACIÓN DE TESTIGOS.pdf");
export const Ebook44 = require("./Código Orgánico General de Procesos (COGEP)/DETERMINACIÓN DE LA CUANTÍA.pdf");
export const Ebook45 = require("./Código Orgánico General de Procesos (COGEP)/DILIGENCIAS PREPARATORIAS.pdf");
export const Ebook46 = require("./Código Orgánico General de Procesos (COGEP)/DISPOSICIONES COMUNES A LOS PROCESOS CONTENCIOSO TRIBUTARIO Y ADMINISTRATIVO.pdf");
export const Ebook47 = require("./Código Orgánico General de Procesos (COGEP)/DISPOSICIONES PRELIMINARES.pdf");
export const Ebook48 = require("./Código Orgánico General de Procesos (COGEP)/DOCUMENTO PRIVADO.pdf");
export const Ebook49 = require("./Código Orgánico General de Procesos (COGEP)/DOCUMENTO PÚBLICO.pdf");
export const Ebook50 = require("./Código Orgánico General de Procesos (COGEP)/DOCUMENTOS QUE ACOMPAÑAN A LA DEMANDA.pdf");
export const Ebook51 = require("./Código Orgánico General de Procesos (COGEP)/EJECUCIÓN DE ACCIONES DE DAR, HACER O NO HACER.pdf");
export const Ebook52 = require("./Código Orgánico General de Procesos (COGEP)/EJECUCIÓN.pdf");
export const Ebook53 = require("./Código Orgánico General de Procesos (COGEP)/ETAPAS DE LA DEMANDA.pdf");
export const Ebook54 = require("./Código Orgánico General de Procesos (COGEP)/EXCUSA Y RECUSACIÓN.pdf");
export const Ebook55 = require("./Código Orgánico General de Procesos (COGEP)/FORMAS EXTRAORDINARIAS DE CONCLUSIÓN DEL PROCESO.pdf");
export const Ebook56 = require("./Código Orgánico General de Procesos (COGEP)/IMPUGNACIÓN.pdf");
export const Ebook57 = require("./Código Orgánico General de Procesos (COGEP)/INSPECCIÓN JUDICIAL.pdf");
export const Ebook58 = require("./Código Orgánico General de Procesos (COGEP)/PLURIDAD DE PRETENSIONES.pdf");
export const Ebook59 = require("./Código Orgánico General de Procesos (COGEP)/PROCEDIMIENTO CONCURSAL.pdf");
export const Ebook60 = require("./Código Orgánico General de Procesos (COGEP)/PROCEDIMIENTO CONTENCIOSO ADMINISTRATIVO.pdf");
export const Ebook61 = require("./Código Orgánico General de Procesos (COGEP)/PROCEDIMIENTO CONTENCIOSO TRIBUTARIO.pdf");
export const Ebook62 = require("./Código Orgánico General de Procesos (COGEP)/PROCEDIMIENTO DE EXCEPCIONES A LA COACTIVA.pdf");
export const Ebook63 = require("./Código Orgánico General de Procesos (COGEP)/PROCEDIMIENTO EJECUTIVO.pdf");
export const Ebook64 = require("./Código Orgánico General de Procesos (COGEP)/PROCEDIMIENTO MONITORIO.pdf");
export const Ebook65 = require("./Código Orgánico General de Procesos (COGEP)/PROCEDIMIENTO ORDINARIO ANUNCIACION DE LA PRUEBA.pdf");
export const Ebook66 = require("./Código Orgánico General de Procesos (COGEP)/PROCEDIMIENTO ORDINARIO AUDIENCIA DE JUICIO.pdf");
export const Ebook67 = require("./Código Orgánico General de Procesos (COGEP)/PROCEDIMIENTO ORDINARIO AUDIENCIA PRELIMINAR.pdf");
export const Ebook68 = require("./Código Orgánico General de Procesos (COGEP)/PROCEDIMIENTO SUMARIO.pdf");
export const Ebook69 = require("./Código Orgánico General de Procesos (COGEP)/PROCEDIMIENTO VOLUNTARIO.pdf");
export const Ebook70 = require("./Código Orgánico General de Procesos (COGEP)/PROCURACIÓN JUDICIAL.pdf");
export const Ebook71 = require("./Código Orgánico General de Procesos (COGEP)/PROVIDENCIAS JUDICIALES.pdf");
export const Ebook72 = require("./Código Orgánico General de Procesos (COGEP)/PROVIDENCIAS PREVENTIVAS.pdf");
export const Ebook73 = require("./Código Orgánico General de Procesos (COGEP)/PRUEBA DOCUMENTAL.pdf");
export const Ebook74 = require("./Código Orgánico General de Procesos (COGEP)/PRUEBA PERICIAL.pdf");
export const Ebook75 = require("./Código Orgánico General de Procesos (COGEP)/PRUEBA TESTIMONIAL.pdf");
export const Ebook76 = require("./Código Orgánico General de Procesos (COGEP)/RECURSO DE APELACIÓN.pdf");
export const Ebook77 = require("./Código Orgánico General de Procesos (COGEP)/RECURSO DE CASACIÓN.pdf");
export const Ebook78 = require("./Código Orgánico General de Procesos (COGEP)/RECURSO DE HECHO.pdf");
export const Ebook79 = require("./Código Orgánico General de Procesos (COGEP)/RENUNCIA A LA PROCURACION JUDICIAL.pdf");
export const Ebook80 = require("./Código Orgánico General de Procesos (COGEP)/SOLEMNIDADES SUSTANCIALES.pdf");
export const Ebook81 = require("./Código Orgánico General de Procesos (COGEP)/SUJETOS PROCESALES.pdf");
export const Ebook82 = require("./Código Orgánico General de Procesos (COGEP)/TERCERÍAS.pdf");
export const Ebook83 = require("./Código Orgánico General de Procesos (COGEP)/TÉRMINOS.pdf");
export const Ebook84 = require("./Procedimiento Civil/ACUMULACIÓN DE DATOS.pdf");
export const Ebook85 = require("./Procedimiento Civil/APERTURA DE LA SUCESIÓN HEREDITARIA.pdf");
export const Ebook86 = require("./Procedimiento Civil/APERTURA Y PUBLICACIÓN DE TESTAMENTO CERRADO.pdf");
export const Ebook87 = require("./Procedimiento Civil/APREMIOS.pdf");
export const Ebook88 = require("./Procedimiento Civil/AUTOS Y DECRETOS.pdf");
export const Ebook89 = require("./Procedimiento Civil/CESIÓN DE BIENES.pdf");
export const Ebook90 = require("./Procedimiento Civil/CITACIÓN.pdf");
export const Ebook91 = require("./Procedimiento Civil/COMPETENCIA.pdf");
export const Ebook92 = require("./Procedimiento Civil/CONCURSO DE ACREEDORES (QUIEBRA).pdf");
export const Ebook93 = require("./Procedimiento Civil/CONTESTACIÓN DE LA DEMANDA.pdf");
export const Ebook94 = require("./Procedimiento Civil/CONVENIO (QUIEBRA).pdf");
export const Ebook95 = require("./Procedimiento Civil/DEMANDA.pdf");
export const Ebook96 = require("./Procedimiento Civil/DESESTIMIENTO.pdf");
export const Ebook97 = require("./Procedimiento Civil/DISOLUCIÓN CONSENSUAL DE LA SOCIEDAD CONYUGAL.pdf");
export const Ebook98 = require("./Procedimiento Civil/EXCEPCIONES.pdf");
export const Ebook99 = require("./Procedimiento Civil/FASE DE EJECUCIÓN DE LA SENTENCIA.pdf");
export const Ebook100 = require("./Procedimiento Civil/INSOLVENCIA.pdf");
export const Ebook101 = require("./Procedimiento Civil/JUICIO COACTIVO.pdf");
export const Ebook102 = require("./Procedimiento Civil/JUICIO CONTRA EL DESPOJO VIOLENTO.pdf");
export const Ebook103 = require("./Procedimiento Civil/JUICIO DE ALIMENTOS.pdf");
export const Ebook104 = require("./Procedimiento Civil/JUICIO DE COMPETENCIA.pdf");
export const Ebook105 = require("./Procedimiento Civil/JUICIO DE CUENTAS SIN TÍTULO EJECUTIVO.pdf");
export const Ebook106 = require("./Procedimiento Civil/JUICIO DE DECLARACIÓN DE HERENCIA YACENTE.pdf");
export const Ebook107 = require("./Procedimiento Civil/JUICIO DE DEMARCACIÓN Y LINDEROS.pdf");
export const Ebook108 = require("./Procedimiento Civil/JUICIO DE DISENSO DE MATRIMONIO DE MENORES.pdf");
export const Ebook109 = require("./Procedimiento Civil/JUICIO DE EMANCIPACIÓN VOLUNTARIA.pdf");
export const Ebook110 = require("./Procedimiento Civil/JUICIO DE EXCUSA.pdf");
export const Ebook111 = require("./Procedimiento Civil/JUICIO DE EXHIBICIÓN.pdf");
export const Ebook112 = require("./Procedimiento Civil/JUICIO DE EXPROPIACIÓN.pdf");
export const Ebook113 = require("./Procedimiento Civil/JUICIO DE FILIACIÓN Y ESTADO CIVIL.pdf");
export const Ebook114 = require("./Procedimiento Civil/JUICIO DE INCAPACIDAD DE GUARDADORES.pdf");
export const Ebook115 = require("./Procedimiento Civil/JUICIO DE INTERDICCIÓN DE DISIPADOR.pdf");
export const Ebook116 = require("./Procedimiento Civil/JUICIO DE INTERDICCIÓN Y REHABILITACIÓN DEL DEMENTE.pdf");
export const Ebook117 = require("./Procedimiento Civil/JUICIO DE INVENTARIOS.pdf");
export const Ebook118 = require("./Procedimiento Civil/JUICIO DE LIQUIDACIÓN Y COBRO DE COSTAS.pdf");
export const Ebook119 = require("./Procedimiento Civil/JUICIO DE NOMBRAMIENTO DE ADMINISTRADOR DE BIENES HEREDITARIOS.pdf");
export const Ebook120 = require("./Procedimiento Civil/JUICIO DE NOMBRAMIENTO DE GUARDADORES.pdf");
export const Ebook121 = require("./Procedimiento Civil/JUICIO DE PARTICIÓN.pdf");
export const Ebook122 = require("./Procedimiento Civil/JUICIO DE RECUSACIÓN.pdf");
export const Ebook123 = require("./Procedimiento Civil/JUICIO DE SERVIDUMBRE.pdf");
export const Ebook124 = require("./Procedimiento Civil/JUICIO EJECUTIVO.pdf");
export const Ebook125 = require("./Procedimiento Civil/JUICIO ORDINARIO.pdf");
export const Ebook126 = require("./Procedimiento Civil/JUICIO VERBAL SUMARIO DE OBRA NUEVA.pdf");
export const Ebook127 = require("./Procedimiento Civil/JUICIO VERBAL SUMARIO.pdf");
export const Ebook128 = require("./Procedimiento Civil/JURISDICCIÓN.pdf");
export const Ebook129 = require("./Procedimiento Civil/LIQUIDACIÓN DE LA MASA EN FALTA DE CONVENIO.pdf");
export const Ebook130 = require("./Procedimiento Civil/LIQUIDACIÓN DE LA SOCIEDAD CONYUGAL.pdf");
export const Ebook131 = require("./Procedimiento Civil/LOS JUICIOS EN GENERAL.pdf");
export const Ebook132 = require("./Procedimiento Civil/NOMBRAMIENTO DE CURADOR AD LITEM.pdf");
export const Ebook133 = require("./Procedimiento Civil/NOMBRAMIENTO DE CURADOR DEL NONATO.pdf");
export const Ebook134 = require("./Procedimiento Civil/NOTIFICACIÓN.pdf");
export const Ebook135 = require("./Procedimiento Civil/NULIDAD Y RESOLUCIÓN DE CONVENIO.pdf");
export const Ebook136 = require("./Procedimiento Civil/NULIDAD PROCESALES.pdf");
export const Ebook137 = require("./Procedimiento Civil/PERSONAS QUE INTERVIENEN EN LOS JUICIOS.pdf");
export const Ebook138 = require("./Procedimiento Civil/PROTOCOLIZACIÓN DE TESTAMENTO ABIERTO.pdf");
export const Ebook139 = require("./Procedimiento Civil/PROVIDENCIAS PREVENTIVAS.pdf");
export const Ebook140 = require("./Procedimiento Civil/PRUEBAS.pdf");
export const Ebook141 = require("./Procedimiento Civil/RECURSO DE APELACIÓN.pdf");
export const Ebook142 = require("./Procedimiento Civil/RECURSO DE HECHO.pdf");
export const Ebook143 = require("./Procedimiento Civil/RECURSOS.pdf");
export const Ebook144 = require("./Procedimiento Civil/REHABILITACIÓN.pdf");
export const Ebook145 = require("./Procedimiento Civil/SENTENCIA.pdf");
export const Ebook146 = require("./Procedimiento Civil/TERCERÍA COADYUVANTE EN JUICIO EJECUTIVO.pdf");
export const Ebook147 = require("./Procedimiento Civil/TERCERÍA EXCLUYENTE EN JUICIO EJECUTIVO.pdf");
export const Ebook148 = require("./Procedimiento Civil/TERCERÍAS EN JUICIO ORDINARIO.pdf");
export const Ebook149 = require("./Procedimiento Civil/TERCERÍAS.pdf");
export const Ebook150 = require("./Procedimiento Civil/TÉRMINOS.pdf");
export const Ebook151 = require("./Procedimiento Penal/FASE PREPROCESAL DE INDAGACION PREVIA.pdf");
export const Ebook152 = require("./Procedimiento Penal/ETAPA DE JUICIO.pdf");
export const Ebook153 = require("./Procedimiento Penal/ETAPA INTERMEDIA.pdf");
export const Ebook154 = require("./Procedimiento Penal/FASE PREPROCESAL DE INDAGACION PREVIA.pdf");
export const Ebook155 = require("./Procedimiento Penal/INSTRUCCIÓN FISCAL.pdf");
export const Ebook156 = require("./Procedimiento Penal/PROCEDIMIENTO ABREVIADO.pdf");
export const Ebook157 = require("./Procedimiento Penal/PROCEDIMIENTO DE ACCIÓN PENAL PRIVADA.pdf");
export const Ebook158 = require("./Procedimiento Penal/PROCEDIMIENTO PARA APLICACIÓN DEL PRINCIPIO DE OPORTUNIDAD.pdf");
export const Ebook159 = require("./Procedimiento Penal/PROCEDIMIENTO PARA DELITOS COMETIDOS POR MEDIOS DE COMUNICACIÓN.pdf");
export const Ebook160 = require("./Procedimiento Penal/PROCEDIMIENTO PARA DELITOS FLAGRANTES.pdf");
export const Ebook161 = require("./Procedimiento Penal/PROCEDIMEINTO PARA JUZGAMIENTO DE CONTRAVENCIONES.pdf");
